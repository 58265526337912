import * as forge from "node-forge";
import cloneDeep from "lodash/cloneDeep";
import { has } from "lodash/object";
import { useAuthStore } from "@/stores/auth";

const encryptors = {
  DEBIT: encryptDebit,
  ACH: encryptAch,
  COINBASE: encryptCoinBase,
  PREPAID: encryptPrepaid,
};

export function encrypt(data, publicKey) {
  if (!publicKey) {
    const authStore = useAuthStore();
    publicKey = authStore.publicKey;
  }

  const buffer = forge.util.createBuffer(data, "utf8");
  const bytes = buffer.getBytes();
  const encrypted = forge.util.encode64(
    publicKey.encrypt(bytes, "RSA-OAEP", {
      md: forge.md.sha512.create(),
    })
  );
  return "ENC_[" + encrypted + "]";
}

function encryptDebit(pm, publicKey) {
  const encrypted = cloneDeep(pm);

  encrypted.card.cardNumber = encrypt(pm.card.cardNumber, publicKey);
  if (!encrypted.card.expiryDate.month) {
    delete encrypted.card.expiryDate;
  } else {
    encrypted.card.expiryDate.month = encrypt(
      pm.card.expiryDate.month,
      publicKey
    );
    encrypted.card.expiryDate.year = encrypt(
      pm.card.expiryDate.year,
      publicKey
    );
  }

  if (encrypted.card.securityCode.length > 0) {
    encrypted.card.securityCode = encrypt(pm.card.securityCode);
  } else {
    encrypted.card.securityCode = "";
  }

  return encrypted;
}

function encryptAch(pm, publicKey) {
  const encrypted = cloneDeep(pm);

  encrypted.ach.accountNumber = encrypt(pm.ach.accountNumber, publicKey);

  return encrypted;
}

function encryptCoinBase(pm, publicKey) {
  const encrypted = cloneDeep(pm);

  encrypted.coinbase.accountNumber = encrypt(
    pm.coinbase.accountNumber,
    publicKey
  );

  return encrypted;
}

function encryptPrepaid(pm, publicKey) {
  const encrypted = cloneDeep(pm);

  if (encrypted.prepaid.accountNumber) {
    encrypted.prepaid.accountNumber = encrypt(
      pm.prepaid.accountNumber,
      publicKey
    );
  } else {
    encrypted.prepaid.cardNumber = encrypt(pm.prepaid.cardNumber, publicKey);
  }

  return encrypted;
}

export function encryptPayoutMethod(payoutMethod) {
  if (!has(encryptors, payoutMethod.source)) return payoutMethod;

  const authStore = useAuthStore();
  return encryptors[payoutMethod.source](payoutMethod, authStore.publicKey);
}
