<template>
  <app-text-field
    v-model.trim="modelValue"
    :rules="rules"
    :label="label"
    v-bind="$attrs"
  />
</template>

<script>
import AppTextField from "@/components/forms/AppTextField";
import { required } from "@/utils/rules";
import { useVModel } from "@vueuse/core";
import { i18n } from "@/plugins/i18n";

export default {
  name: "NameTextField",

  components: { AppTextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: null },
    label: { type: [String, Object], default: "" },
    requiredMsg: { type: String, default: null },
  },

  setup(props, { emit }) {
    const modelValue = useVModel(props, "value", emit, { eventName: "input" });

    return {
      modelValue,
      required,
    };
  },

  data() {
    return {
      rules: [this.required(this.requiredMsg), this.validChars],
    };
  },

  methods: {
    validChars(v) {
      return (
        /^([a-z.'\s-]+)$/i.test(v) ||
        i18n.global.t("NameTextField.invalidNameChars", {
          label: this.label,
        })
      );
    },
  },
};
</script>
