<template>
  <div class="my-8 px-1 px-sm-3">
    <div data-test="new-payout-method-info-section">
      {{ $t("NewPayoutMethods.info") }}
    </div>

    <portal v-if="isGuest" to="methodsPayoutStatus">
      <guest-payout-status
        v-if="selected"
        :payout="selected"
        class="d-flex justify-center"
        :class="xs && 'px-2'"
      />
    </portal>

    <v-row class="mt-8" role="list">
      <v-col
        v-for="method in methods"
        :key="method.source"
        cols="12"
        sm="4"
        align="center"
      >
        <new-payout-method-card
          role="listitem"
          :method="method"
          :data-test="method.testId"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewPayoutMethodCard from "./new-payout-method-card/NewPayoutMethodCard";
import { mdiBank, mdiCreditCardOutline } from "@mdi/js"; //mdiCheckbook
import PayPayLogo from "@/assets/img/payout-methods/Paypal-newLogo.svg?inline";
import VenmoLogo from "@/assets/img/payout-methods/Venmo-new-logo.svg?inline";
import MoneyNetworkLogo from "@/assets/img/payout-methods/moneynetwork-newLogo.svg?inline";
import UsDebitCardLogo from "@/assets/img/payout-methods/usdebitcard.svg?inline";
import CoinBaseLogo from "@/assets/img/payout-methods/coinbase.svg?inline";
import achLogo from "@/assets/img/payout-methods/ach.svg?inline";
import echeckLogo from "@/assets/img/payout-methods/checkbook_color.svg?inline";
import visaLogo from "@/assets/img/visa.svg?inline";
import masterCardLogo from "@/assets/img/mastercard.svg?inline";
import GuestPayoutDetail from "@/components/guest/GuestPayoutDetail";
import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDynamicContent } from "@/composables/dynamic-content";
import { useBack } from "@/composables/back";
import { resolveErrorMsg } from "@/utils/error-handler";
import { getTooltipTimeFrame } from "@/utils/payout";
import { useAppStore } from "@/stores/app";
import AchForm from "@/components/payout-methods/ach/AchForm";
import DebitCardForm from "@/components/payout-methods/debit/DebitCardForm";
import CryptoWalletContinueNew from "@/components/payout-methods/crypto-wallet/CryptoWalletContinueNew";
import MoneyNetworkForm from "@/components/payout-methods/money-network/MoneyNetworkForm";
import GuestPayoutStatus from "@/components/guest/GuestPayoutStatus.vue";
import UsDebitCard from "@/components/payout-methods/us-debit-card/UsDebitCard";
import { useDisplay } from "vuetify";

// async imports to avoid issues with circular dependencies
// https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
import VenmoForm from "@/components/payout-methods/venmo/VenmoForm";
import PaypalForm from "@/components/payout-methods/paypal/PaypalForm";
import ECheckForm from "@/components/payout-methods/eCheck/ECheckForm";
import RegisteredUserECheckForm from "@/components/payout-methods/eCheck/RegisteredUserECheckForm";

export default {
  name: "NewPayoutMethods",

  components: { NewPayoutMethodCard, GuestPayoutStatus },

  setup() {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();
    const title = authStore.isGuest
      ? i18n.global.t("NewPayoutMethods.selectPayoutMethod")
      : i18n.global.t("NewPayoutMethods.title");
    useTitle(title);
    const { setContent } = useDynamicContent();
    useBack(() => setContent(GuestPayoutDetail));
    const { xs } = useDisplay();
    return {
      authStore,
      payoutStore,
      payoutMethodStore,
      appStore,
      xs,
    };
  },

  data() {
    return {
      mdiBank,
      mdiCreditCardOutline,
    };
  },

  computed: {
    isUser() {
      return this.authStore.isUser;
    },

    isGuest() {
      return this.authStore.isGuest;
    },
    allowed() {
      return this.payoutMethodStore.allowed;
    },

    selected() {
      return this.payoutStore.selected;
    },

    all() {
      const all = [
        {
          source: "ACH",
          name: i18n.global.t("NewPayoutMethods.ach.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("ACH")
            : i18n.global.t("NewPayoutMethods.ach.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.ach.tooltip", {
            from: 2,
            to: 3,
          }),
          graphic: {
            type: "svg",
            src: achLogo,
          },
          testId: "new-payout-method-bank-account",
          component: AchForm,
        },
        {
          source: "DEBIT",
          name: i18n.global.t("NewPayoutMethods.debit.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("DEBIT")
            : i18n.global.t("NewPayoutMethods.debit.timeFrame"),
          tooltip: this.payoutStore.selected
            ? getTooltipTimeFrame("DEBIT", "")
            : i18n.global.t("NewPayoutMethods.debit.tooltip", {
                timeFrame: "same day",
              }),
          graphic: {
            type: "icon",
            src: mdiCreditCardOutline,
          },
          testId: "new-payout-method-credit-card",
          component: DebitCardForm,
        },
        {
          source: "VISA",
          name: i18n.global.t("NewPayoutMethods.debit.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("DEBIT")
            : i18n.global.t("NewPayoutMethods.visa.timeFrame"),
          tooltip: this.payoutStore.selected
            ? getTooltipTimeFrame("DEBIT", "VISA")
            : i18n.global.t("DebitCardForm.addPayoutVisatooltipContent", {
                timeFrame: "same day",
              }),
          graphic: {
            type: "svg",
            src: visaLogo,
          },
          testId: "new-payout-method-visa-card",
          component: DebitCardForm,
        },
        {
          source: "MASTERCARD",
          name: i18n.global.t("NewPayoutMethods.debit.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("DEBIT")
            : i18n.global.t("NewPayoutMethods.mastercard.timeFrame"),
          tooltip: this.payoutStore.selected
            ? getTooltipTimeFrame("DEBIT", "MASTERCARD")
            : i18n.global.t("DebitCardForm.addPayoutMasertooltipContent", {
                timeFrame: "same day",
              }),
          graphic: {
            type: "svg",
            src: masterCardLogo,
          },
          testId: "new-payout-method-master-card",
          component: DebitCardForm,
        },
        {
          source: "VENMO",
          name: i18n.global.t("NewPayoutMethods.venmo.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("VENMO")
            : i18n.global.t("NewPayoutMethods.venmo.timeFrame"),
          tooltip: this.payoutStore.selected
            ? getTooltipTimeFrame("VENMO", "")
            : i18n.global.t("NewPayoutMethods.venmo.tooltip", {
                timeFrame: "same day",
              }),
          graphic: {
            type: "svg",
            src: VenmoLogo,
          },
          testId: "new-payout-method-venmo",
          component: VenmoForm,
        },
        {
          source: "PAYPAL",
          name: i18n.global.t("NewPayoutMethods.paypal.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("PAYPAL")
            : i18n.global.t("NewPayoutMethods.paypal.timeFrame"),
          tooltip: this.payoutStore.selected
            ? getTooltipTimeFrame("PAYPAL", "")
            : i18n.global.t("NewPayoutMethods.paypal.tooltip", {
                timeFrame: "same day",
              }),
          graphic: {
            type: "svg",
            src: PayPayLogo,
          },
          testId: "new-payout-method-paypal",
          component: PaypalForm,
        },
        {
          source: "ECHECK",
          name: i18n.global.t("NewPayoutMethods.eCheck.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("ECHECK")
            : i18n.global.t("NewPayoutMethods.eCheck.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.eCheck.tooltip"),
          graphic: {
            type: "svg",
            src: echeckLogo,
          },
          testId: "new-payout-method-eCheck",
          component: this.isGuest ? ECheckForm : RegisteredUserECheckForm,
        },
        {
          source: "COINBASE",
          name: i18n.global.t("NewPayoutMethods.cryptowallet.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("COINBASE")
            : i18n.global.t("NewPayoutMethods.cryptowallet.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.cryptowallet.tooltip"),
          graphic: {
            type: "svg",
            src: CoinBaseLogo,
          },
          testId: "new-payout-method-crypto",
          component: CryptoWalletContinueNew,
        },
        {
          source: "US_DEBIT",
          name: i18n.global.t("NewPayoutMethods.usdebit.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("PREPAID")
            : i18n.global.t("NewPayoutMethods.usdebit.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.usdebit.tooltip"),
          graphic: {
            type: "svg",
            src: UsDebitCardLogo,
          },
          testId: "new-payout-method-US-money-network",
          component: UsDebitCard,
        },
        {
          source: "MONEYNETWORK",
          name: i18n.global.t("NewPayoutMethods.prepaid.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("PREPAID")
            : i18n.global.t("NewPayoutMethods.prepaid.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.prepaid.tooltip"),
          graphic: {
            type: "svg",
            src: MoneyNetworkLogo,
          },
          testId: "new-payout-method-money-network",
          component: MoneyNetworkForm,
        },
      ];

      if (process.env.VUE_APP_MONEY_NETWORK_ENABLED === "true") {
        all.push({
          source: "PREPAID",
          name: i18n.global.t("NewPayoutMethods.prepaid.name"),
          timeFrame: this.payoutStore.selected
            ? this.getTimeFrame("PREPAID")
            : i18n.global.t("NewPayoutMethods.prepaid.timeFrame"),
          tooltip: i18n.global.t("NewPayoutMethods.prepaid.tooltip"),
          graphic: {
            type: "svg",
            src: MoneyNetworkLogo,
          },
          testId: "new-payout-method-money-network",
          component: MoneyNetworkForm,
        });
      }

      return all;
    },
    allowedSources() {
      return this.allowed
        .map((a) => {
          if (a.source === "DEBIT") {
            return a.brands;
          }

          if (a.source === "PREPAID" && "brands" in a) {
            return a.brands;
          }
          return a.source;
        })
        .flat();
    },

    methods() {
      if (this.isUser && !this.selected) {
        return this.isCompanyUser
          ? this.all.filter((obj) => {
              return (
                obj.source !== "PREPAID" &&
                obj.source !== "VENMO" &&
                obj.source !== "PAYPAL" &&
                obj.source !== "ECHECK" &&
                obj.source !== "DEBIT" &&
                obj.source !== "US_DEBIT" &&
                obj.source !== "MONEYNETWORK"
              );
            })
          : this.all.filter((obj) => {
              if (obj.source === "PREPAID" && "brands" in obj) {
                return (
                  obj.source !== "VENMO" &&
                  obj.source !== "PAYPAL" &&
                  obj.source !== "ECHECK" &&
                  obj.source !== "DEBIT" &&
                  obj.source !== "US_DEBIT" &&
                  obj.source !== "PREPAID"
                );
              } else {
                return (
                  obj.source !== "VENMO" &&
                  obj.source !== "PAYPAL" &&
                  obj.source !== "ECHECK" &&
                  obj.source !== "DEBIT" &&
                  obj.source !== "US_DEBIT" &&
                  obj.source !== "PREPAID"
                );
              }
            });
      }

      return this.isCompanyUser
        ? this.all.filter(
            (obj) =>
              this.allowedSources.includes(obj.source) &&
              obj.source !== "MONEYNETWORK" &&
              obj.source !== "PREPAID" &&
              obj.source !== "DEBIT"
          )
        : this.all.filter(
            (m) =>
              this.allowedSources.includes(m.source) && m.source !== "DEBIT"
          );
    },

    user() {
      return this.authStore.user;
    },

    isCompanyUser() {
      return this.user.recipientType.toLowerCase() === "company";
    },
  },
  created() {
    if (this.payoutStore.selected) {
      this.getMerchantConfig();
    }
  },
  methods: {
    async getMerchantConfig() {
      try {
        await this.payoutStore.fetchMerchantConfig(
          this.selected.merchant.originatorId
        );
      } catch (err) {
        const msg = resolveErrorMsg(err, "AcceptPayout.default");
        this.appStore.showError(msg);
      }
    },
    getTimeFrame(source) {
      const method = this.payoutMethodStore.allowed.filter(
        (obj) => obj.source === source
      );
      if (method.length > 0) {
        const from = method[0]?.transferTime?.min;
        const to = method[0]?.transferTime?.max;
        if (to === 0 && from === 0) {
          return i18n.global.t("NewPayoutMethods.genericTimeFrameSameDay");
        } else if (to > 0 && from > 0) {
          return i18n.global.t("NewPayoutMethods.genericTimeFrameFromTo", {
            from: from,
            to: to,
          });
        } else if (from === 0 && to >= 1) {
          return i18n.global.t("NewPayoutMethods.genericTimeFrameOnlyDay", {
            to: to,
          });
        } else {
          if (method[0].source === "ACH")
            return i18n.global.t("NewPayoutMethods.ach.timeFrame");
          if (
            method[0].source === "DEBIT" ||
            method[0].source === "VISA" ||
            method[0].source === "MASTERCARD"
          )
            return i18n.global.t("NewPayoutMethods.debit.timeFrame");
          if (method[0].source === "VENMO")
            return i18n.global.t("NewPayoutMethods.venmo.timeFrame");
          if (method[0].source === "PAYPAL")
            return i18n.global.t("NewPayoutMethods.paypal.timeFrame");
          if (method[0].source === "ECHECK")
            return i18n.global.t("NewPayoutMethods.eCheck.timeFrame");
          if (method[0].source === "COINBASE")
            return i18n.global.t("NewPayoutMethods.cryptowallet.timeFrame");
          if (method[0].source === "PREPAID")
            return i18n.global.t("NewPayoutMethods.prepaid.timeFrame");
        }
      }
    },
  },
};
</script>
