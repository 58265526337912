//String constants
const paymentStatusCustomerService =
  "If you have questions, contact the Customer Support phone number provided in email correspondence you received about this payout.";
const shortDateFormat = "MM/dd/yyyy";
const emailRequiredMsg = "Please enter an email address.";
const mobileNumberRequiredMsg = "Please enter your mobile number.";
const pwsDoNotMatch = "Passwords do not match. Please try again.";
const mobileNumbersDoNotMatch =
  "The Mobile numbers entered do not match. Please try again.";
const pwdSpecialChars =
  "{'^'}{'@'}{'#'}{'!'}{'$'}{'%'}{'&'}{'-'}{'+'}{'='}{')'}{')'}{']'} {'*'}";

// [^@#!$%&-+=()]*"
export default {
  // Misc paths
  dateFormat: {
    short: shortDateFormat,
    long: "EEEE, MMMM d, yyyy",
  },

  //Error code paths
  errorCodes: {
    SignInForm: {
      default: "There was an error signing in.",
      400091: "The email or password you have entered is not correct.",
      401098: "The email or password you have entered is not correct.",
      400103: "Your account is locked. Please retry in 30 minutes.",
      400107:
        "Your password has expired. Please click Reset Password below to create a new one.",
      login: "Login",
    },

    ForgotPasswordOtp: {
      default: "There was an error verifying your passcode.",
    },

    ForgotPasswordForm: {
      default: "There was an error submitting your request.",
      400091: "The email or password you have entered is not correct.",
    },

    ResetPasswordForm: {
      default: "There was an error resetting your password.",
    },

    ResetPasswordOtp: {
      default: "There was an error requesting your new passcode.",
    },

    Payouts: {
      payoutsByMerchant:
        "There was an error retrieving your payouts by merchant",
    },

    Dashboard: {
      availablePayoutsList:
        "There was an error retrieving your available payouts",
      payoutsByMerchant:
        "There was an error retrieving your payouts by merchant",
    },

    DashboardPayoutHistory: {
      payoutHistory: "There was an error retrieving your latest payouts",
    },

    PayoutMethods: {
      fetchNetworkError: "There was an error retrieving your payout methods",
    },

    PayoutMethodOptions: {
      deleteNetworkError: "There was an error deleting your payout method",
    },

    PayoutDetailsActions: {
      default: "There was an error approving your payout.",
    },

    AcceptPayout: {
      default: "There was an error processing your request.",
    },

    RejectPayout: {
      default: "There was an error rejecting your payout.",
    },

    ProfileInfo: {
      update: "There was an error updating your profile information.",
    },

    Profile: {
      retrieve: "There was an error retrieving your contact information",
    },

    AlertPreferences: {
      default: "There was an error updating your alert preferences.",
    },

    MoneyNetworkSuccess: {
      fetchNetworkError: "There was an error retrieving your payout methods",
    },

    DebitCardForm: {
      default: "There was an error saving your debit card.",
      400201:
        "This card is not supported and cannot be saved or used to collect payments on this platform. Please try to store another debit card.",
      ////  400044: "This card is not supported and cannot be saved or used to collect payments on this platform. Please try to store another debit card.",
      400044:
        "The card does not support this type of payment. Please try with another card.",
      500000:
        "There was an issue processing your transaction. Please try again or choose a different payout method.",
      500007:
        "There was an issue processing your transaction. Please try again or choose a different payout method.",
      400130:
        "The card does not support this type of payment. Please try with another card.",
      400059:
        "The card does not support this type of payment. Please try with another card.",
    },

    AchForm: {
      default:
        "We are unable to process your request at this time. Please correct your bank information and try again.",
      blueCheckError:
        "Account number previously flagged as fraud, please try a different account number.",
      400212:
        "The provided bank details are not supported and cannot be saved or used to collect payments on this platform. Please correct and try to save again.",
      400049:
        "The routing number entered is not valid. Please verify the routing number and try again.",
      500000:
        "There was an issue processing your transaction. Please try again or choose a different payout method.",
      500007:
        "There was an issue processing your transaction. Please try again or choose a different payout method.",
    },

    MoneyNetworkForm: {
      default:
        "We encountered an issue. Please try again later or click cancel and provide a different payment method.",
    },

    OtpVerification: {
      verify: {
        default: "There was an error verifying your passcode.",
        401021:
          "Your account has been locked due to too many invalid passcode entries. Please try again in 30 minutes.",
        401091: "Invalid or Missing OTP.",
        401093: {
          101: "Your account has been locked due to too many invalid passcode entries. Please try again in 30 minutes.",
          103: "The payment has been cancelled due to too many invalid passcode attempts. A check will be mailed to you.",
          104: "OTP has been rejected",
          default:
            "Your account has been locked due to too many invalid passcode entries. Please try again in 30 minutes.",
        },
      },
      requestNew: {
        default: "There was an error requesting your new passcode.",
        400103: "Your account is locked. Please retry in 30 minutes.",
      },
    },

    GuestOptIn: {
      auth: "There was an error continuing with your payout.",
    },

    GuestPayoutLayout: {
      payoutLayout: "There was an error retrieving your payout.",
    },

    GuestAuth: {
      cancelPayment: "There was an error cancelling your payout.",
    },

    Registration: {
      default: "There was an issue while registering your account.",
      409100: "Recipient already registered",
    },

    PayoutsAvailable: {
      default: "There was an error retrieving your available payouts",
    },

    NewMoneyNetworkForm: {
      400301: "Invalid request or data format. Please try again.",
      400303: "User Name already in use. Please choose another User Name.",
      400304:
        "We are unable to create a Money Network card for you at this time.",
      400305:
        "We are unable to create a Money Network card for you at this time.",
      400306: "Invalid or missing SSN.",
      400307: "Invalid or missing home phone.",
      400308: "Invalid or missing work phone.",
      400309: "Invalid or missing street in primary address.",
      400310: "Invalid or missing street2 in primary address.",
      400311: "Invalid or missing street in shipping address.",
      400312: "Invalid or missing street2 in shipping address.",
      400313: "Invalid or missing country in primary address.",
      400314: "Invalid or missing country in primary address.",
      400315: "Invalid or missing state or province in primary address.",
      400316: "Invalid or missing zip/postalCode in shipping address.",
      400317: "Invalid or missing country in shipping address.",
      400318: "Invalid or missing state in shipping address.",
      400319: "Invalid or missing first name.",
      400320: "Invalid or missing last name.",
      400321: "Invalid or missing username.",
      400322: "Invalid or missing password.",
      400323: "Invalid or missing date of birth.",
      400324: "Invalid or missing city in primary address.",
      400325: "Invalid or missing city in shipping address.",
      400326: "Invalid or missing email.",
      default:
        "There was an error while creating your Money Network card. Please try again.",
    },

    genericErrors: {
      default: "An error occurred.",
      404001: "No data found for the request.",
      401001: "Unauthorized",
      403: "This page is no longer accessible. For questions, please contact Customer Support at the phone number provided in email correspondence you received regarding this payout.",
    },

    disburse: {
      DEBIT: {
        default:
          "We are unable to process your request at this time. Please correct your information and try again.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400041: "Please correct your card information and try again.",
        500000:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        500007:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400063:
          "There was an issue processing your payment. " +
          paymentStatusCustomerService,
        400064:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400201: {
          1: "We are unable to process your request at this time. Please correct your card information and try again.",
          2: "We are unable to process your request at this time. Please correct your card information and try again.",
          3: "We are unable to process your request at this time. Please correct your card information and try again.",
          4:
            "We are unable to process your request. Please select a new payout method or new debit card. You have 1 attempt left to accept this payment. " +
            "If your next attempt fails, this payment will be canceled in this portal and mailed to you instead.",
        },
        400044: {
          ////1: "The debit card entered cannot accept this payment. Try another debit card or select a different payout method.",
          default:
            "The card entered is not eligible for this payment. Please try a different card or select another payout method.",
          1: "The card entered is not eligible for this payment. Please try a different card or select another payout method.",
          2:
            "The debit card entered cannot accept this payment. Continued failed attempts will cause this payment to be cancelled. " +
            "Please select a different payout method.",
          3: "You have entered debit cards that cannot accept this payment. Please try to collect your funds using a different payout method.",
          4:
            "We are unable to process your request. Please select a new payout method or new debit card. You have 1 attempt " +
            "left to accept this payment. If your next attempt fails, this payment will be canceled in this portal and mailed to you instead.",
        },
        400123:
          "This transaction is currently in process and can only be accessed by one of the listed recipients at a time. Please try again later.",
        400028:
          "We are unable to process your request at this time. Please correct your card information and try again.",
        400073:
          "You have exceeded the number of attempts allowed to collect your payment and it can no longer be accepted in this portal. " +
          "This payment will be mailed to you.",
        // 400130: "The card does not support this type of payment. Please try again with another card or payment method.",
        400130:
          "The card entered is not eligible for this payment. Please try a different card or select another payout method.",
        400129: "The payout has already been claimed by another payee.",
        400133:
          "It looks like your card is expiring soon. We cannot post funds to an expired card. Please use a different debit card or select another method to continue accepting this payout.",
        500002:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400136:
          "We are unable to execute your request at the moment. The name provided by the merchant does not match the data from the payment method. Please contact Merchant to update your name or select another payment method.",
        400137:
          "We are unable to execute your request at the moment. Please make sure that you have entered the correct CVV number or select another payment method.",
      },

      ACH: {
        default:
          "We are unable to process your request at this time. Please correct your bank information and try again.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400049:
          "The routing number entered is not valid. Please verify the routing number and try again.",
        500000:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        500007:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400211:
          "The routing number entered is not valid. Please verify the routing number and try again.",
        400212:
          "The provided bank details are not supported and cannot be used to collect payments on this platform. Please correct the information or select a different payout method.",
        400213:
          "We encountered an issue processing your payment. We will continue processing and email you once this has been completed.",
        400063:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400064:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400123:
          "This transaction is currently in process and can only be accessed by one of the listed recipients at a time. Please try again later.",
        400202: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400058: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400207: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400134: {
          1: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          2: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          3: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          4:
            "We can't proceed with the Account information you provided. Please verify the account information & try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400129: "The payout has already been claimed by another payee.",
        400135: {
          1: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          2: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          3: "We can't proceed with the Account information you provided. Please verify the account information & try again.",
          4:
            "We can't proceed with the Account information you provided. Please verify the account information & try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        500002:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
      },

      PAYPAL: {
        default:
          "We are unable to process your request at this time. Please correct your information and try again.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400206:
          "Please confirm your email/phone number with Paypal. Please note that the funds will be returned to the source if they are not claimed within 30 days.",
        400205: {
          1: "We are unable to process your request. Please select a different payout method to collect your payment.",
          2: "We are unable to process your request. Please select a different payout method to collect your payment.",
          3: "We are unable to process your request. Please select a different payout method to collect your payment.",
          4:
            "We are unable to process your request. Please select a different payout method to collect your payment. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400125: "Invalid or missing phone number details.",
        400123:
          "This transaction is currently in process and can only be accessed by one of the listed recipients at a time. Please try again later.",
        400063:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400064:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        500000:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        500007:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400129: "The payout has already been claimed by another payee.",
      },

      VENMO: {
        default:
          "We are unable to process your request at this time. Please correct your information and try again.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400206:
          "Please confirm your email/phone number with Venmo. Please note that the funds will be returned to the source if they are not claimed within 30 days.",
        400205: {
          1: "We are unable to process your request. Please select a different payout method to collect your payment.",
          2: "We are unable to process your request. Please select a different payout method to collect your payment.",
          3: "We are unable to process your request. Please select a different payout method to collect your payment.",
          4:
            "We are unable to process your request. Please select a different payout method to collect your payment. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400125: "Invalid or missing phone number details.",
        400123:
          "This transaction is currently in process and can only be accessed by one of the listed recipients at a time. Please try again later.",
        400063:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400064:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400129: "The payout has already been claimed by another payee.",
      },

      ECHECK: {
        default:
          "We encountered an issue requesting your eCheck. Please try another disbursement method.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400350:
          "We encountered an issue requesting your eCheck. Please try another disbursement method.",
        400351:
          "We encountered an issue requesting your eCheck. Please try another disbursement method.",
        400352:
          "We encountered an issue requesting your eCheck. Please try another disbursement method.",
        400355:
          "We encountered an issue requesting your eCheck. Please try another disbursement method.",
        500000:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        500007:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400129: "The payout has already been claimed by another payee.",
      },

      COINBASE: {
        default:
          "We are unable to process your request at this time. Please correct your bank information and try again.",
        400049:
          "The routing number entered is not valid. Please verify the routing number and try again.",
        400078:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        500000:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        500007:
          "There was an issue processing your transaction. Please try again or choose a different payout method.",
        400211:
          "The routing number entered is not valid. Please verify the routing number and try again.",
        400212:
          "The provided bank details are not supported and cannot be used to collect payments on this platform. Please correct the information or select a different payout method.",
        400213:
          "We encountered an issue processing your payment. We will continue processing and email you once this has been completed.",
        400063:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400064:
          "There was an issue processing your payment. Please contact Customer Support for assistance.",
        400123:
          "This transaction is currently in process and can only be accessed by one of the listed recipients at a time. Please try again later.",
        400202: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400058: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400207: {
          1: "We are unable to process your request at this time. Please correct your bank information and try again.",
          2: "We are unable to process your request at this time. Please correct your bank information and try again.",
          3: "We are unable to process your request at this time. Please correct your bank information and try again.",
          4:
            "We are unable to process your request at this time. Please correct your bank information and try again. " +
            "You have 1 attempt left to accept this payment. If your next attempt fails, this payment will be canceled in " +
            "this portal and mailed to you instead.",
        },
        400129: "The payout has already been claimed by another payee.",
      },
      PREPAID: {
        400301: "Card Not Supported.",
      },
    },
  },

  //Payment status mapping paths
  paymentStatus: {
    completed: {
      label: "Completed",
      message: {
        title: "The payment you are trying to access has been completed.",
        subtext: paymentStatusCustomerService,
      },
    },

    reversed: {
      label: "Reversed",
      message: {
        title: "The payment you are trying to access has been reversed.",
        subtext: paymentStatusCustomerService,
      },
    },

    accepted: "Accepted",
    claimed: {
      label: "Claimed",
      message: {
        title: "This payment is being processed.",
        subtext: "You should receive a confirmation email shortly.",
      },
    },

    inReview: {
      label: "Accepted",
      message: {
        title: "This payment is being processed.",
        subtext: "You should receive a confirmation email shortly.",
      },
    },

    pendingReview: {
      label: "In progress",
      message: {
        title: "The payout is being processed.",
        subtext: "You should receive a confirmation email shortly.",
      },
    },

    paymentHold: {
      message: {
        title: "Your payout is in the process of being disbursed.",
      },
    },

    awaitingApproval: {
      message: {
        title: "You have already claimed this payment.",
        subtext:
          "However, funds cannot be paid until all listed parties have approved.",
      },
    },

    rejected: {
      label: "Rejected",
      message: {
        title: "The payment you are trying to access is rejected.",
        subtext: paymentStatusCustomerService,
      },
    },

    canceled: {
      label: "Canceled",
      message: {
        title: "The payment you are trying to access is canceled.",
        subtext: paymentStatusCustomerService,
      },
    },

    expired: {
      label: "Expired",
      message: {
        title: "The payment you are trying to access is expired.",
        subtext: paymentStatusCustomerService,
      },
    },

    failed: {
      label: "Failed",
      message: {
        title: "The payment you are trying to access has failed.",
        subtext: paymentStatusCustomerService,
      },
    },

    reversalSuccess: {
      label: "Reversal Success",
      message: {
        title: "The payment you are trying to access has been reversed.",
        subtext: paymentStatusCustomerService,
      },
    },

    reversalPending: {
      label: "Reversal Pending",
      message: {
        title:
          "There is a reversal pending in the payment you are trying to access.",
        subtext: paymentStatusCustomerService,
      },
    },

    reversalFailed: {
      label: "Reversal Failed",
      message: {
        title: "The attempt to reverse this payment has failed.",
        subtext: paymentStatusCustomerService,
      },
    },

    disbursementPending: "Acceptance Pending",

    multi: {
      pending: "Approval Pending",
      approved: "Approved",
      claimed: "Claimed",
      declined: "Declined",
    },

    disbursementDeclined: {
      label: "The previous attempt to collect this payout was declined.",
      subText:
        "Please try again. You may need to try a different payout method",
    },
  },

  rules: {
    requiredField: "This field is required.",
    emailIsValid: "Valid email address required.",
    betweenNumeric: "Must be between {min} and {max} digits.",
    orNumeric: "Please enter a valid {min} or {max} digits card number",
    isDateBefore: "Start date must come before end date",
    isDateAfter: "End date must come after start date",
    cardIsValid: "Only Visa/Mastercard is allowed.",
    visaIsValid:
      "Invalid Visa card number. Please check your input and try again.",
    masterIsValid:
      "Invalid Mastercard card number. Please check your input and try again.",
  },

  payoutMethods: {
    ach: "Bank Account",
    checking: "Checking",
    savings: "Savings",
    debit: "Debit",
    prepaid: "Money Network",
    venmo: "Venmo",
    paypal: "Paypal",
    eCheck: "eCheck",
    coinbase: "Coinbase",
  },

  states: {
    alabama: "Alabama",
    alaska: "Alaska",
    americanSamoa: "American Samoa",
    arizona: "Arizona",
    arkansas: "Arkansas",
    california: "California",
    colorado: "Colorado",
    connecticut: "Connecticut",
    delaware: "Delaware",
    districtOfColumbia: "District Of Columbia",
    federatedStatesOfMicronesia: "Federated States Of Micronesia",
    florida: "Florida",
    georgia: "Georgia",
    guam: "Guam",
    hawaii: "Hawaii",
    idaho: "Idaho",
    illinois: "Illinois",
    indiana: "Indiana",
    iowa: "Iowa",
    kansas: "Kansas",
    kentucky: "Kentucky",
    louisiana: "Louisiana",
    maine: "Maine",
    marshallIslands: "Marshall Islands",
    maryland: "Maryland",
    massachusetts: "Massachusetts",
    michigan: "Michigan",
    minnesota: "Minnesota",
    mississippi: "Mississippi",
    missouri: "Missouri",
    montana: "Montana",
    nebraska: "Nebraska",
    nevada: "Nevada",
    newHampshire: "New Hampshire",
    newJersey: "New Jersey",
    newMexico: "New Mexico",
    newYork: "New York",
    northCarolina: "North Carolina",
    northDakota: "North Dakota",
    northernMarianaIslands: "Northern Mariana Islands",
    ohio: "Ohio",
    oklahoma: "Oklahoma",
    oregon: "Oregon",
    palau: "Palau",
    pennsylvania: "Pennsylvania",
    puertoRico: "Puerto Rico",
    rhodeIsland: "Rhode Island",
    southCarolina: "South Carolina",
    southDakota: "South Dakota",
    tennessee: "Tennessee",
    texas: "Texas",
    utah: "Utah",
    vermont: "Vermont",
    virginIslands: "Virgin Islands",
    virginia: "Virginia",
    washington: "Washington",
    westVirginia: "West Virginia",
    wisconsin: "Wisconsin",
    wyoming: "Wyoming",
    apoEurope: "APO Europe",
    apoAmericas: "APO Americas",
    apoPacific: "APO Pacific",
  },

  // Route paths
  routes: {
    dashboard: {
      title: "Dashboard",
    },

    payouts: {
      title: "Payouts",
    },

    profile: {
      title: "Profile",
    },

    signIn: {
      title: "Sign In",
    },

    resetPassword: {
      title: "Reset Password",
    },

    registration: {
      title: "Registration",
    },

    sessionEnded: {
      title: "Session Ended",
    },

    privacyPolicy: {
      title: "Privacy Policy",
    },

    terms: {
      title: "Terms of Use",
    },

    guestAuth: {
      title: "Guest Authentication",
    },

    guestPaymentCancelled: {
      title: "Payout Cancelled",
    },

    guestPayout: {
      title: "Guest Payout",
    },

    guestRejectPayout: {
      title: "Reject Payout",
    },

    guestPayoutRejected: {
      title: "Payout Rejected",
    },

    error: {
      title: "Error",
    },

    info: {
      title: "App Info",
    },

    faq: {
      title: "Frequently Asked Questions",
    },

    notFound: {
      title: "Not Found",
    },
  },

  // Component paths
  AuthCover: {
    signIn: {
      info: "Your Carat account enables you to receive payouts from many vendors.",
    },
    register: {
      info: "Your Payout Awaits!",
    },
  },

  RegistrationSuccess: {
    success: "Your account has been registered!",
    clickToLogin: "Click Sign In to continue",
    signIn: "Sign In",
  },

  DashboardPayoutHistory: {
    title: "Payout History",
    barGraphListElements: "This is a list of the most recent payouts.",
    reviewAllHistoryLabel: "Review All Payout History",
    reviewAll: "Review All",
  },

  DashboardPayoutHistoryList: {
    label: "Latest Payouts",
  },

  PayoutHistoryBarChart: {
    graphAriaLabel:
      "This is a bar graph of the most recent payouts. Payout details are in the following list.",
  },

  DashboardPayoutsList: {
    availablePayouts: "Available Payouts",
    reviewAll: "Review All",
    reviewAllAvailableLabel: "Review All Available Payouts",

    header: "Available Payout | Available Payouts",
    noPayouts: "No Available Payouts",
  },

  DashboardPayoutsListItemExpires: {
    expires: "Expires",
  },

  DashboardGreeting: {
    greeting: "Welcome",
    greetingSubtext: "Here are today's highlights",
  },

  DashboardPayoutTile: {
    acceptAction: "Accept",
  },

  DashboardTodaysHighlights: {
    todaysHighlights: "Today's Highlights",
    dashboardTile: {
      availablePayouts: "Available Payouts",
      currentPayoutAmount: "Current Balance",
      totalMoneyReceived: " Total Money Received",
    },
  },

  AchClassificationSelect: {
    accountClassification: "Account Classification",
    personal: "Personal",
    business: "Business",
  },

  AppTextField: {
    invalidChars: "{label} contains invalid characters.",
  },

  CityField: {
    required: "Please enter your city.",
    minLength: "City name must have at least 3 characters.",
  },

  StreetAddressField: {
    required: "Please enter your street address.",
    minLength: "Address must have at least 5 characters.",
  },

  ZipCodeField: {
    required: "Please enter your zipcode.",
    inputPattern: "Zipcode should be (XXXXX or XXXXX-XXXX)",
  },

  ConsentCheckbox: {
    optInAgreement:
      "By checking this box, you’re expressly consenting to receive a one-time passcode by text or phone call (using automated means) from or on behalf of Fiserv Inc. for the digital payouts service at details (eg email or phone number) provided. Standard text messaging rates may apply based on your plan with your mobile phone carrier.",
    privacyPolicy: "Privacy Policy",
    terms: "Terms of Use",
  },

  DateTextField: {
    dateFormat:
      "Please enter a valid date (" + shortDateFormat.toUpperCase() + ")",
  },

  DoingBusinessAsTextField: {
    businessName: "Please enter your business name.",
    businessNameMinLength:
      "Your business name must have at least 2 characters.",
  },

  NameTextField: {
    invalidNameChars:
      "{label} can only contain letters, spaces, periods, apostrophes, and hyphens.",
  },

  PhoneNumberField: {
    phoneNumberLength: "Mobile number must have at least 10 digits.",
    validPhoneNumber: "Please enter a valid phone number.",
  },

  TinTextField: {
    tinRequired: "Please enter your company TIN number.",
    invalidTinFormat: "Invalid TIN format.",
  },

  AmoutTextField: {
    amountRequired: "Please enter the amount",
    invalidAmountRange: "Invalid Amount Range",
    validation: "Please enter numeric value only",
    compareMin: "minimum amount is greater than maximum amount",
    compareMax: "maximum amount is lesser than minimum amount",
  },

  GuestOptIn: {
    optInHeader: "Opt in to receive a one-time passcode via text",
    optInCheckBoxName: "Opt In Agreement Check Box",
    continue: "Continue",
  },

  GuestPayoutDetail: {
    title: "Payout Details",
  },

  GuestPayoutSummary: {
    heading: "Payout Summary",
    issued: "Payout Issued:",
    issuedMobile: "Issued:",
    expiration: "Expiration Date:",
    expirationMobile: "Expires:",
  },

  GuestTransfer: {
    title: "Transfer to Account",
    transferTo: "Transfer To:",
    estimatedArrivalAch: "Estimated Arrival:",
    estimatedArrival: "Estimated Arrival: Same day",
    estimatedArrivalAchHelp:
      "Transfer speeds may vary and could take up to 3 business days to complete.",
    estimatedArrivalHelp:
      "Transfer speeds may vary, but if all information entered is correct, payment should post to your account shortly.",
    transfer: "Transfer",
    cancel: "Cancel",
    timeoutErrorMsg:
      "We encountered an issue processing your payment. We will continue processing and email you once this has been completed.",

    confirmCancelDialog: {
      title: "Are you sure you want to cancel?",
      cancel: "Cancel",
      content:
        "If you cancel, all data will be lost. Click {0} to continue or {1} to enter a different payout method.",
      close: "Close",
    },
  },

  GuestTransferSuccess: {
    claimedNotice:
      'This payment has been accepted. All recipients must approve before the payment can be issued to the recipient marked "Claimed".',
    claimedInstruction:
      "Please write down and save your transaction number in case you need to refer to it later: {txId}",
    processed: "Processed:",
    paymentAccepted: "Payout Accepted:",
    payoutExpectedDepositDate: "Payout Expected Deposit Date:",
    transactionId: "Transaction ID:",
    createAccount: "Create Carat Digital Payout Account",
    title: "Payout Success",
    inReviewTitle: "Payout Processed",
    inReviewMessage:
      "Your {merchant} claim payout is in the process of being disbursed.",
    payoutClaimed: "Payment Information Accepted!",
    payoutSuccess: "Your payout is on its way!",
    eCheckMessage:
      "You will receive an email with an instruction on how to print the check for deposit.",
  },

  MultiRecipientStatusOther: {
    title: "Approval status of other parties",
    payoutMethodLabel: "Payout Method:",
    accountNumberLabel: "Account:",
    finePrint:
      "Applicable fees may be applied to disbursement. Please review the fee amounts displayed beneath the Disbursement Method for amounts.",
  },

  OtpVerification: {
    title: "OTP Verification",
    requestingNew: "Requesting new passcode...",
    enterBelow: "Enter it below",
    inputLabel: "One-time passcode",
    expiration: "Passcode  expires in",
    expired: "The OTP has expired. Request a new passcode below.",
    requestNew: "Request new passcode",
    button: "Verify",
    emailChannel: "A one-time passcode has been emailed to",
    smsChannel:
      "A one-time passcode has been sent to your mobile device ending in",
    voiceChannel:
      "A one-time passcode has been sent to your mobile device ending in",
    otpLength: "OTP should be 6 digits",
  },

  AchForm: {
    info: "Payments collected by Bank Transfer will take 2-3 business days to post to your account.",
    firstName: "First Name",
    lastName: "Last Name",
    accountNumber: "Account Number",
    confirmAccountNumber: "Confirm Account Number",
    accountType: "Account Type",
    financialInstitutionName: "Financial Institution Name",
    routingNumber: "Routing Number",
    routingNumberHelpIcon: "Routing Number Help Icon",
    routingNumberToolTip:
      "You can find your routing number at the bottom of a check.",
    routingOrTransitNumber: "Routing/Transit Number",
    checkNumber: "Check Number",
    viewAndAcceptTC: "I have viewed and accepted the ",
    termsAndConditions: "Terms and Conditions.",
    defaultPayoutMethod: "Make this my default payout method.",
    submit: "Submit",
    addAccount: "Add Account",
    cancel: "Cancel",
    checking: "Checking",
    savings: "Savings",
    guestTitle: "Add Bank Account",
    bankAccount: "Bank Account",
    confirmAccountNumbers: "Account numbers do not match. Please re-enter.",
    networkError:
      "There was an error retrieving your financial institution name.",
    invalidRoutingNumber:
      "The routing number entered is not valid. Please verify the routing number and try again.",
  },

  DebitCardForm: {
    note: "Note: Instant deposit to debit card",
    masterNote: "Note: {timeFrame} deposit to Mastercard",
    visaNote: "Note: {timeFrame} deposit to Visa card",
    readMore: "Read More",
    addPayoutVisatooltipContent:
      "By selecting this payment method you will receive your payment within {timeFrame}  " +
      "to your Visa card. Be sure to enter card information " +
      "correctly to avoid any issues with receiving your payment.",
    addPayoutMasertooltipContent:
      "By selecting this payment method you will receive your payment within {timeFrame} " +
      "to your Mastercard. Be sure to enter card information " +
      "correctly to avoid any issues with receiving your payment.",
    visaTooltipContent:
      "By selecting this payment method you will receive your payment within {timeFrame} " +
      "to your Visa card. Be sure to enter card information " +
      "correctly to avoid any issues with receiving your payment. Payments over " +
      "{maxAmount} cannot be collected on the Visa card.",
    masterTooltipContent:
      "By selecting this payment method you will receive your payment within {timeFrame} " +
      "to your Mastercard. Be sure to enter card information " +
      "correctly to avoid any issues with receiving your payment. Payments over " +
      "{maxAmount} cannot be collected on the Mastercard.",
    nameOnCard: "Name on Card",
    firstName: "First Name",
    lastName: "Last Name",
    cardNumber: "Card Number",
    reEnter: "Re-enter Card Number",
    expDate: "Expiry Date (MM/YY)",
    acceptTerms: "I have viewed and accepted the",
    termsConditions: "Terms and Conditions",
    defaultPayoutMethod: "Make this my default payout method.",
    cancel: "Cancel",
    addDebitCard: "Add Debit Card",
    debitCard: "Debit Card",
    addVisaCard: "Add Visa Card",
    visaCard: "Visa Card",
    addMasterCard: "Add Mastercard",
    masterCard: "Mastercard",
    submit: "Submit",
    addAccount: "Add Account",
    confirmCardNumbers:
      "The card number entered does not match. Please try again.",
    expiredCard: "Please enter a valid expiration date.",
    cvcNumber: "CVC/CVV",
    invalidCVCFormat: "Invalid cvc format",
  },

  CoinBaseForm: {
    firstName: "First Name",
    lastName: "Last Name",
    accountNumber: "Account Number",
    confirmAccountNumber: "Confirm Account Number",
    routingNumber: "Routing Number",
    routingNumberToolTip:
      "You can find your routing number at the bottom of a check.",
    routingOrTransitNumber: "Routing/Transit Number",
    checkNumber: "Check Number",
    viewAndAcceptTC: "I have viewed and accepted the ",
    termsAndConditions: "Terms and Conditions.",
    defaultPayoutMethod: "Make this my default payout method.",
    submit: "Submit",
    addAccount: "Add Account",
    cancel: "Cancel",
    guestTitle: "Add Coinbase",
    bankAccount: "Coinbase",
    confirmAccountNumbers: "Account numbers do not match. Please re-enter.",
    cryptoBasedmethods: "Crypto Methods",
    continueInfo: "If you have a Coinbase Wallet, please click 'continue'",
    networkError: "There was an error retrieving your routing number.",
    needAcoinWallet:
      "Need a Coinbase Wallet? Click the link to access the Coinbase website.",
    newCoinbaseWallet: "New Coinbase Wallet",
    disclaimerCoinbase: `Once your coinbase wallet has been created, please return to the
    recipient portal, select the 'Current Coinbase Wallet' option and enter
    your account details to complete the disbursement request.`,
  },

  MoneyNetworkForm: {
    moneyNetwork: "Money Network",
    addMoneyNetwork: "Add Money Network",
    cardInfo:
      "To deposit funds to your Money Network card please provide the information below.",
    accountInfo:
      "To deposit funds to your Money Network account please provide the information below.",
    firstName: "First Name",
    lastName: "Last Name",
    accountNumber: "Account Number",
    confirmAccountNumber: "Confirm Account Number",
    cardNumber: "Card Number",
    confirmCardNumber: "Confirm Card Number",
    confirmAccountNumbers: "Account numbers do not match. Please re-enter.",
    confirmCardNumbers:
      "The card number entered does not match. Please try again.",
    defaultPayoutMethod: "Make this my default payout method.",
    addAccount: "Add Account",
    submit: "Submit",
    cancel: "Cancel",
    noCard: "Don't have a Money Network Card?",
    signup: "Signup",
    challengeQuestionsForm: {
      title: "Money Network Challenge Questions",
      note: "Note: You must click and view the document shared below and click the first checkbox before proceeding.",
      importantInfoHeader:
        "IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT",
      importantInfo:
        "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask your name, address, date of birth and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.",
      enroll: "Enroll",
      cancel: "Cancel",
      challengeQuestionsHeader: "Challenge Questions",
      challengeAnswerLabel: "Please Select One",
    },
  },

  UsDebitCardForm: {
    cardInfo:
      "To deposit funds on your prepaid US Debit Card, please provide the information below.",
    addUsDebitCard: "Add US Debit Card",
    usDebitCard: "US Debit Card",
    firstName: "First name",
    lastName: "Last name",
    cardNumber: "Card Number",
    confirmCardNumber: "Confirm Card Number",
    confirmCardNumbers:
      "The card number entered does not match. Please try again.",
    defaultPayoutMethod: "Make this my default payout method.",
    addAccount: "Add Account",
    submit: "Submit",
    cancel: "Cancel",
    optionExplaination:
      "Has {merchant} ever paid funds to you on a prepaid US Debit Card before?",
    explainationOne:
      "The prepaid US Debit Card you order through this site is for {merchant} payments ONLY. No other person or entity can fund this card.",
    explainationTwo:
      "If you order a new prepaid US Debit Card, you will receive your physical card in 7-10 days. You will get a virtual card the same day by email. Your virtual card provides the card number, expiration date, and security code for you to be able to use the card right away.",
    explainationThree:
      "Add your Card to Apple Pay® or Google Pay™ for contactless payments.",
    disclaimer: "DISCLAIMER",
    disclaimerText:
      "Google Play, the Google Play logo, and Google Pay are trademarks of Google LLC. Apple, the Apple logo, Apple Pay, iPhone, and iPad are trademarks of Apple Inc., registered in the U.S. and other countries and regions.",
    yesOption: "Yes",
    noOption: "No",
  },

  NewPayoutMethodCardDesktop: {
    nonSavedAccountMessage:
      "You cannot save this account type to your profile. However you can accept payouts using this method when the payer allows it.",
  },

  NewPayoutMethodCardMobile: {
    nonSavedAccountMessage:
      "You cannot save this account type to your profile. However you can accept payouts using this method when the payer allows it.",
  },

  ReadMoreTooltip: {
    readMore: "Read More",
    readMoreAria: "Read more about adding ",
  },

  PaypalForm: {
    note: "Note: You must have a valid email address to accept funds to your existing PayPal account.",
    readMore: "Read More",
    tooltipContent:
      "By selecting this option, your payment will be credited to your existing PayPal account. ALERT: It is important you enter the correct email. An incorrect email could result in the funds being irreversibly deposited to the wrong account. This payment should post to your PayPal account within {timeFrame}.",
    // "By selecting this option, your payment will be credited to your existing PayPal account. An incorrect email could result in the funds being irreversibly deposited to the wrong account. This payment should post to your PayPal account within {timeFrame}.",
    extraNote:
      "Please enter email address associated with your PayPal account. Review the information before clicking Submit to ensure there are no errors. Once you click Submit, this payout will be processed and cannot be canceled or reversed.",
    paypalMethod: "Paypal Method",
    email: "Email",
    confirmEmail: "Confirm Email",
    mobileNumber: "Mobile number",
    confirmMobileNumber: "Confirm Mobile Number",
    acceptTerms: "I have viewed and accepted the",
    termsConditions: "Terms and Conditions",
    submit: "Submit",
    cancel: "Cancel",
    emailAddress: "Email address",
    paypal: "Paypal",
    addPaypal: "Add Paypal",
    emailRequired: emailRequiredMsg,
    mobileNumberRequired: mobileNumberRequiredMsg,
    confirmMobile: "Please confirm your mobile number.",
    emailDoesNotMatch:
      "The email addresses entered do not match. Please try again.",
    mobileNumbersDoNotMatch: mobileNumbersDoNotMatch,
    acceptTermsHeader: "Accept PayPal Terms",
    acceptTermsMessage:
      "PayPal may request your SSN/TIN once you receive $600 USD in the payments for goods and services within a calendar year. Click {0} for more information.",
    acceptTermsContinue: "Continue",
    here: "here",
  },

  VenmoForm: {
    /* DSDDPBS-1657 Commit */
    note: `Note: You must have a US mobile number to receive funds to your
    existing Venmo account.`,
    readMore: "Read More",
    tooltipContent:
      "By selecting this option, your payment will be credited to your existing Venmo account. ALERT: It is important you enter the correct phone number. An incorrect phone number could result in the funds being irreversibly deposited to the wrong account. This payment should post to your PayPal account within {timeFrame}.",
    disclaimer: `Please enter the US mobile number associated with your Venmo wallet to receive your payout.
     Review the information before clicking Submit to ensure there are no errors. Once you click Submit, this payout will be processed and cannot be canceled or reversed.`,
    mobile: "Mobile",
    confirmMobile: "Confirm Mobile Number",
    acceptTerms: "I have viewed and accepted the",
    termsConditions: "Terms and Conditions",
    submit: "Submit",
    cancel: "Cancel",
    venmo: "Venmo",
    addVenmo: "Add Venmo",
    mobileNumberRequired: mobileNumberRequiredMsg,
    mobileNumbersDoNotMatch: mobileNumbersDoNotMatch,
    acceptTermsHeader: "Accept Venmo Terms",
    acceptTermsMessage:
      "Venmo may request your SSN/TIN once you receive $600 USD in the payments for goods and services within a calendar year. Click {0} for more information.",
    acceptTermsContinue: "Continue",
    here: "here",
  },

  NewPayoutMethods: {
    info: "Please select one of the following payout methods.",
    selectPayoutMethod: "Select Payout Method",
    title: "Add New Payout Methods",
    cryptoInfo: "Please select a crypto payment wallet.",
    genericTimeFrameFromTo: "{from}-{to} days",
    genericTimeFrameSameDay: "Same Day",
    genericTimeFrameOnlyDay: "{to} day",
    ach: {
      name: "Bank Account",
      timeFrame: "2-3 days",
      tooltip: `By selecting this method, your payment will be deposited 
      into your bank account within {from}-{to} business days. 
      Be sure to enter account information correctly to avoid any 
      issues with receiving your payment.`,
    },
    debit: {
      name: "Debit Card",
      timeFrame: "Same Day",
      tooltip: `By selecting this method, you will receive your payment within {timeFrame} to your Visa or Mastercard debit card. Be sure to enter card information 
      correctly to avoid any issues with receiving your payment. 
      Payments over $50,000 cannot be collected on the debit card.`,
    },
    venmo: {
      name: "Wallet",
      timeFrame: "Same Day",
      tooltip:
        "By selecting this option, your payment will be credited to your existing Venmo account. ALERT: It is important you enter the correct phone number. An incorrect phone number could result in the funds being irreversibly deposited to the wrong account. This payment should post to your Venmo account within {timeFrame}.",
      // "By selecting this option, your payment will be credited to your existing Venmo account. An incorrect phone number could result in the funds being irreversibly deposited to the wrong account. This payment should post to your Venmo account within {timeFrame}.",
    },
    paypal: {
      name: "Wallet",
      timeFrame: "Same Day",
      tooltip:
        "By selecting this option, your payment will be credited to your existing PayPal account. ALERT: It is important you enter the correct email. An incorrect email could result in the funds being irreversibly deposited to the wrong account. This payment should post to your PayPal account within {timeFrame}.",
    },
    eCheck: {
      name: "eCheck",
      timeFrame: "Same Day",
      tooltip:
        "By selecting this method, you will receive your payout via electronic check (eCheck). A link to download and print the check will be emailed to you from Checkbook.io and should arrive in your email today. Once you print or download the check, you can deposit it via mobile capture (picture) or by taking the printed check to your bank or credit union.",
    },
    cryptowallet: {
      name: "Crypto Wallet",
      timeFrame: "1-3 days",
      tooltip: `By selecting this method, your payment will be deposited 
        into your bank account within 1-3 business days. 
        Be sure to enter account information correctly to avoid any 
        issues with receiving your payment.`,
      coinbase: {
        timeFrame: "1-3 days",
        name: "coinbase",
        tooltip: `By selecting this method, your payment will be deposited 
        into your bank account within 1-3 business days. 
        Be sure to enter account information correctly to avoid any 
        issues with receiving your payment.`,
      },
      bakkt: {
        timeFrame: "1-3 days",
        name: "bakkt",
        tooltip: `By selecting this method, your payment will be deposited 
        into your bank account within 1-3 business days. 
        Be sure to enter account information correctly to avoid any 
        issues with receiving your payment.`,
      },
      ftx: {
        timeFrame: "1-3 days",
        name: "ftx",
        tooltip: `By selecting this method, your payment will be deposited 
        into your bank account within 1-3 business days. 
        Be sure to enter account information correctly to avoid any 
        issues with receiving your payment.`,
      },
    },
    prepaid: {
      name: "Prepaid Card",
      timeFrame: "Same Day",
      tooltip:
        "By Selecting this method, your payment will be deposited into your Money Network account within same day. Be sure to enter account information correctly to avoid any issues with receiving your payment.",
    },
    visa: {
      name: "Visa",
      timeFrame: "Same Day",
      tooltip: `By selecting this method, you will receive your payment within {timeFrame} day 
      to your Visa card. Be sure to enter card information 
      correctly to avoid any issues with receiving your payment. 
      Payments over $50,000 cannot be collected on the Visa card.`,
    },
    mastercard: {
      name: "mastercard",
      timeFrame: "Same Day",
      tooltip: `By selecting this method, you will receive your payment within {timeFrame} day 
      to your Mastercard card. Be sure to enter card information 
      correctly to avoid any issues with receiving your payment. 
      Payments over $50,000 cannot be collected on the master card.`,
    },
    usdebit: {
      name: "Prepaid Card",
      timeFrame: "Same Day",
      tooltip:
        "If you select this method, you will get your payment on a prepaid US Debit Card. This prepaid card can only be funded by FEMA through this site. No other person or entity can fund this card. Payments over $10,000 can’t be added on the prepaid card. If you order a new prepaid US Debit Card, you will receive your physical card in 7-10 days. You will get a virtual card the same day by email. Your virtual card provides the card number, expiration date, and security code for you to be able to use the card right away.",
      cardtype: "Prepaid Card",
    },
  },

  PayoutMethodCard: {
    expirationLabel: "Exp:",
    defaultTooltip: "Default Payout Method",
    checking: "Checking",
    savings: "Savings",
    debit: "Debit",
    venmo: "Venmo",
    paypal: "Paypal",
    coinbase: "coinbase",
    prepaid: "Money Network",
  },

  PayoutMethodOptions: {
    editButtonText: "Edit Account",
    deleteButtonText: "Delete Account",
    confirmDialog: {
      title: "Do you want to continue?",
      content: "This payout method will be deleted.",
      confirmButtonText: "Continue",
    },
  },

  PayoutMethods: {
    title: "Payout Methods",
    addNewLabel: "Add New Payout Method",
    addNew: "Add New",
    tooltip:
      "Maximum number of accounts has been reached. Please delete one before proceeding.",
    addPayoutMethod: "Add Payout Method",
    listItemLabel: "Payout Method",
  },

  PayoutMethodsConfirmCancelDialog: {
    title: "Are you sure you want to cancel?",
    cancel: "Cancel",
    close: "Close",
    content:
      "If you cancel, all data will be lost. Click {0} to continue editing or {1} to enter a different payout method.",
    editContent:
      "If you cancel, all data will be lost. Click {0} to continue editing or {1} to return to the Dashboard.",
  },

  PayoutMethodsListItem: {
    closeLabel: "Close Payout Method Options",
    optionsLabel: "Payout Method Options",
  },

  AcceptPayout: {
    transferTo: "Transfer To:",
    transferToTooltip:
      "Don't see your payout method? Payout methods displayed are methods that have been authorized by the company you do business with.",
    estimatedArrivalAch: "Estimated Arrival: 3 business days",
    estimatedArrival: "Estimated Arrival: {timeFrame}",
    estimatedArrivalAchHelp:
      "Transfer speeds may vary and could take up to 2-3 business days to complete.",
    estimatedArrivalHelp:
      "Transfer speeds may vary, but if all information entered is correct, payment should post to your account shortly.",
    submit: "Submit",
    cancel: "Cancel",
    addPayoutMethod: "Add Payout Method",
    cvvTooltip: "The security code is located on the back of your card.",
    title: "Accept Payout",
    timeoutErrorMsg:
      "We encountered an issue processing your payment. We will continue processing and email you once this has been completed.",
  },

  ConfirmDisbursePayout: {
    claimedNotice:
      'This payment has been accepted. All recipients must approve before the payment can be issued to the recipient marked "Claimed".',
    claimedInstruction:
      "Please write down and save your transaction number in case you need to refer to it later: {txId}",
    processed: "Processed: ",
    transactionId: "Transaction ID: ",
    paymentAccepted: "Payout Accepted:",
    payoutExpectedDepositDate: "Payout Expected Deposit Date:",
    done: "Done",
    title: "Payout Success",
    inReviewTitle: "Payout Processed",
    inReviewMessage:
      "Your {merchant} claim payout is in the process of being disbursed.",
    inPaymentReviewOrHoldMessage:
      "Your payout is in the process of being disbursed.",
    payoutClaimed: "Payment Information Accepted!",
    payoutSuccess: "Your payout is on its way!",
    eCheckMessage:
      "You will receive an email with an instruction on how to print the check for deposit.",
  },

  PayoutMethodSelect: {
    activatorLabel: "Transfer To Payout Method",
    noSelection: "Select Payout Method",
    addNew: "Add New Payout Method",
  },

  PayoutsAvailable: {
    noPayouts: "No Available Payouts",
    noSavedPayouts: "No Saved Payouts",
    savePayoutAccount: "Save this payout method.",
    savePayoutAccountError:
      "You have saved the maximum of 5 payout methods.  If you would like to save this payout method, you must first delete one of your existing payout methods before proceeding with your payout.  You can submit this payout without saving.",
  },

  PayoutsAvailableListItem: {
    issued: "Issued",
    expires: "Expires",
  },

  PayoutsHistory: {
    noPayouts: "No Payouts to Display",
    networkError: "There was an error retrieving your payouts history",
  },

  PayoutsHistoryFilters: {
    minAmountLable: "Min Amount",
    maxAmountLable: "Max Amount",
    filter: "Filter",
    filterBy: "Filter By",
    show: "Show",
    status: "Status",
    merchant: "Merchant",
    startDate: "Start Date (MM/DD/YYYY)",
    endDate: "End Date (MM/DD/YYYY)",
    search: "Search",
    searchResults:
      "Search results for transactions created from {startDate} to {endDate}",
    last30Days: "Last 30 Days",
    last60Days: "Last 60 Days",
    last90Days: "Last 90 Days",
    dateRange: "Select Date Range",
    all: "All",
    disbursed: "Disbursed",
    cancelled: "Canceled",
    failed: "Failed",
    expired: "Expired",
    reversed: "Reversed",
    rejected: "Rejected",
    networkError: "There was an error retrieving your payouts history",
    download: "Download",
    downloadTooltip: "Download History",
    sortList: "Sort List",
    amountSort: "Amount Sort",
    merchantSort: "Merchant Sort",
    merchantName: "Merchant Name",
    amount: "Amount",
    filterAmount: "Filter Amount",
  },

  PayoutsHistoryListItem: {
    updated: "Updated",
  },

  PayoutsHistoryPagination: {
    sizeLabel: "Items per page",
    previousPage: "Previous Page",
    nextPage: "Next Page",
    totalLabel: "of {n} item | of {n} items",
  },

  ConfirmRejectPayout: {
    payoutRejected: "Your payout has been rejected",
    processed: "Processed:",
    tid: "Transaction ID:",
    reason: "Reason for rejecting:",
    done: "Done",
    confirmationTitle: "Reject Payout Confirmation",
  },

  PayoutDetails: {
    title: "Payout Details",
  },

  PayoutDetailsActions: {
    reject: "Reject",
    accept: "Accept",
    approve: "Approve",
    multiRecipientOtherUserClaimed: `By approving, recipients agree to funds being paid to the account designated by the recipient marked "Claimed".`,
  },

  PayoutsListView: {
    title: "Payouts",
    availableTab: "Available",
    historyTab: "History",
    total: "Total",
  },

  PayoutSummary: {
    transferredTo: "Transferred to",
    issued: "Issued",
    expires: "Expires",
  },

  RejectPayout: {
    areYouSure: "Are you sure you want to reject these payouts?",
    reason: "Reason for rejecting:",
    reject: "Reject",
    cancel: "Cancel",
    title: "Reject Payout",
    reasonRequired: "Reason is required.",
    networkError: {
      available: "There was an error retrieving your available payouts",
      history: "There was an error retrieving your payouts history",
      latest: "There was an error retrieving your latest payouts",
    },
  },

  ActivityTracker: {
    sessionTimeoutDialog: {
      title: "Session Timeout",
      confirmButtonText: "OK",
      content: `Please click Okay to continue or you will be logged out in {0} minutes.`,
    },
  },

  PayoutByMerchant: {
    title: "Payout by Merchant",
    otherGroupName: "other",
    otherBusinessName: "Other",
    ariaLabel: "Payout by Merchant Doughnut Chart",
  },

  PayoutByMerchantDoughnut: {
    total: "Total",
    label: "Payout by Merchant",
  },

  PayoutByMerchantSummary: {
    listLabel: "By Merchant",
    total: "Total",
  },

  AlertPreferences: {
    title: "One-Time Passcode Delivery Preference",
    editLabel: "Edit one-time passcode delivery preference",
    edit: "Edit",
    optInCheckBoxName: "Opt In Agreement Check Box",
    cancel: "Cancel",
    confirm: "Confirm",
    email: "Email",
    sms: "Text Message",
    voice: "Phone Call",
  },

  ProfileInfo: {
    heading: "My Profile",
    edit: "Edit Profile Info",
    doingBusinessAs: "Business Name",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    street: "Street Address",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    DOB: "Date of Birth",
    TIN: "TIN",
    cancel: "Cancel",
    update: "Save Changes",
    updateDescription: "Update Recipient",
    mobileNumberRequired: mobileNumberRequiredMsg,
    stateValidation: "Please enter your state.",
    dob: "Please enter your date of birth.",
  },

  OtpDeliveryOptionForm: {
    optInAgreement:
      "By checking this box, you’re expressly consenting to receive a one-time passcode by text or phone call (using automated means) from or on behalf of Fiserv Inc. for the digital payouts service at details (eg email or phone number) provided. Standard text messaging rates may apply based on your plan with your mobile phone carrier. ",
    guestOptInAgreement:
      "By checking this box, you’re expressly consenting to receive a one-time passcode by text or phone call (using automated means) from or on behalf of Fiserv Inc. for the digital payouts service at details (eg email or phone number) provided. Standard text messaging rates may apply based on your plan with your mobile phone carrier.",
    privacyPolicy: "Privacy Policy",
    terms: "Terms of Use",
    requestCode: "Request Code",
    heading:
      "Before you can access your account, we need to verify your identity using an identification code",
    headingFund:
      "Before you can access your funds, we need to verify your identity using an identification code",
    headingPayment:
      "Before you can access your payout, we need to verify your identity using an identification code",
  },

  ForgotPasswordForm: {
    enterEmail: "Please enter a registered email address.",
    emailLabel: "Registered email address",
    optInAgreement:
      "By checking this box, you’re expressly consenting to receive a one-time passcode by text (using automated means) from or on behalf of Fiserv Inc. for the digital payouts service at details ( eg email or phone number ) provided. You also consent and agree to {1} and acknowledge receipt of our {0}. Standard text messaging rates may apply based on your plan with your mobile phone carrier.",
    privacyPolicy: "Privacy Policy",
    terms: "Terms of Use",
    submit: "Submit",
    emailFailedTitle: "Reset Request Failed",
    emailRequired: emailRequiredMsg,
  },

  ForgotPasswordOtp: {
    recoveryPasscode: "Recovery Passcode",
    enterOtp: "Please enter the one-time passcode sent to your email.",
    otpLabel: "One-time Passcode",
    submit: "Submit",
    otpFailedTitle: "OTP Failed",
    otpChannelSubtitle_one:
      "Please enter the one-time passcode sent to your email ",
    otpChannelSubtitle_two: "and select the secondary OTP channel below.",
    expiration: "Passcode expires in",
    expired: "OTP has expired. Redirecting...",
    expiringErrTitle: "Passcode expires in ",
    expiringErrMessage:
      "You will be redirected back to request a new passcode.",
  },

  ResetPasswordForm: {
    setNewPw: "Set New Password",
    confirmPw: "Confirm Password",
    submit: "Submit",
    resetPwFailed: "Reset Password Failed",
    pwsDoNotMatch: pwsDoNotMatch,
  },

  ResetPasswordOtp: {
    otpLabel: "One-time Passcode",
    otpLength: "OTP should be 6 digits.",
    requestingNew: "Requesting new passcode...",
    smsChannel:
      "A one-time passcode has been sent to your mobile device ending in",
    expiration: "Session expires in",
    expired: "The OTP has expired. Request a new passcode below.",
    requestNew: "Request new passcode",
    requestOtpFailedTitle: "OTP Request Failed",
    expiringTitle: "Session expires in ",
    expiringMessage: "You will be redirected back to request a new passcode.",
  },

  ResetPasswordSuccess: {
    successMsg: "You have successfully changed your password.",
    successBtn: "Sign In",
  },

  PasswordField: {
    ariaTitle: "Your new password must follow all of the following rules.",
    charMin: "Between 9 and 20 characters",
    number: "One number character",
    uppercase: "One UPPERCASE character",
    lowercase: "One lowercase character",
    ariaSpecial:
      "One special character of opening bracket, closing bracket, caret, at symbol, number sign, exclamation point, dollar sign, percent sign, ampersand, minus sign, plus sign, equal sign, opening parenthesis, closing parenthesis, asterisk, or quotation mark",
    consecutive: "No more than two repeated characters allowed",
    title: "Password Requirement",
    special: "One special character of " + pwdSpecialChars,
    pw: "Password",
    pwLength: "Password must be between 9 and 20 characters.",
    pwContainsNumber: "Password must contain one number.",
    pwContainsUppercase: "Password must contain one uppercase character.",
    pwContainsLowercase: "Password must contain one lowercase character.",
    pwContainsSpecial: "Password must contain one special character.",
    pwNonConsecutive:
      "Password must not contain more than two repeated characters.",
    pwNoSpace: "Password must not contain space character.",
  },

  SignInForm: {
    signIn: "Sign In",
    email: "Email",
    pw: "Password",
    forgotPw: "Forgot Password?",
    failed: "Sign In Failed",
    resetPw: "Reset Password",
    emailRequired: emailRequiredMsg,
    pwRequired: "Please enter your password.",
    needHelp: "Need help?",
    privacyNotice: "Privacy Notice",
  },

  ConfirmDialog: {
    title: "Are you sure?",
    contentText: "Do you want to continue?",
    confirmButtonText: "Okay",
    cancelButtonText: "Cancel",
  },

  DialogPanel: {
    close: "Close",
  },

  RecipientStatus: {
    label: "Status:",
  },

  TheErrorAlert: {
    close: "Close",
  },

  TheFooter: {
    privacyNotice: "Privacy Notice",
    onlinePrivacyNotice: "Online Privacy Notice",
    terms: "Terms and Conditions",
    copyright: "Fiserv, Inc. or its affiliates",
    copyright_001: "Fiserv, Inc. as processor on behalf of Pathward, N.A.",
    copyright_002: "Fiserv, Inc. as processor on behalf of Flagstar Bank, N.A.",
    copyright_default: "Fiserv, Inc. as processor on behalf of Pathward, N.A.",
    copyright_whileError: "Fiserv, Inc.",
    copyright_bancorp: "Flagstar Bank., NA Privacy Notice",
  },

  TheMain: {
    heading: "Main Content",
  },

  TheMobileBottomNavBar: {
    menu: "Menu",
    dashboard: "Dashboard",
    payouts: "Payouts",
    userMenuLabel: "User Menu",
    faq: "FAQ",
  },

  TheNavBar: {
    menu: "Menu",
    dashboard: "Dashboard",
    payouts: "Payouts",
    faq: "FAQ",
    exit: "Exit",
    help: "Help",
  },

  FrequentlyAskedQuestions: {
    title: "Frequently Asked Questions",
    back: "Back",
    search: "Search",
    searchNotFound:
      "No Frequently Asked Questions(FAQ's) found as per the search criteria.",
    faqMessage:
      "The questions listed here pertain to accessing this portal. For other questions, please click the FAQs link at the top of this page once you've completed the multi-factor authentication.",
  },

  TheSkipLinks: {
    listLabel: "Skip to",
    main: "Main Content",
    nav: "Navigation",
    footer: "Footer",
  },

  TheUserMenu: {
    profileSettings: "Profile Settings",
    logout: "Logout",
  },

  TheUserMenuButton: {
    btnLabel: "User Menu",
  },

  GuestPayoutLayout: {
    title: "Guest Payout",
    back: "Back",
    complementRoute: "has loaded",
    backlabel: "Reject Payout",
  },

  Registration: {
    title: "Registration",
    PI: "Personal Information",
    doingBusinessAs: "Doing Business As",
    tin: "Tax Identification Number",
    firstName: "First Name",
    lastName: "Last Name",
    DOB: "Date of Birth",
    phone: "Phone Number",
    address: "Address",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip Code",
    setPW: "Set Password",
    email: "E-mail (Used as username)",
    confirmPW: "Confirm Password",
    checkboxHeading: "Accept Terms and Conditions Checkbox",
    acceptTerms: "I have viewed and accepted the",
    termsConditions: "Terms and Conditions",
    submit: "Submit",
    cancel: "Cancel",
    pwsDoNotMatch: pwsDoNotMatch,
  },

  ResetPassword: {
    title: "Reset Password",
  },

  SessionEnded: {
    title: "Session Ended",
    directions: "To return, click continue.",
    continue: "Continue",
    description: {
      timeout: "You have been logged out due to inactivity on the portal.",
      exit: "You have successfully exited the system.",
    },
  },

  SignIn: {
    title: "Sign In",
    otpFailedTitle: "OTP Request Failed",
    authFailedTitle: "OTP Authentication Failed",
    login: "Login",
    authenticationGreeting:
      "A payout from {merchant} needs your authentication !",
    authenticationGreetingGeneric: "A payout needs your authentication !",
    signIn: "Already have an Account ? {0}",
    signInText: "Sign-in",
    signInLabel: "Sign in link",
    cancel:
      "If you do not want to link this payment to your profile, click {0}.",
    cancelPaymentLinkLabel: "Skip Payment Linking",
    cancelPaymentLinkText: "here",
    dialogTitle: "Skip Payment Linking!",
    dialogContent:
      "Are you sure you want to skip this payment linking and land to dashboard page?",
    dialogCancel: "Dashboard",
    back: "Back",
  },

  GuestAuth: {
    merchantGreeting: "Your payout from {merchant} is ready!",
    genericGreeting: "Your payout is ready!",
    fiservIntroduction:
      "You have now entered the Carat Digital Payouts portal, operated by Fiserv Inc, that allows you to accept payouts from {merchant}.",
    cancel: "If you choose not to continue, you may cancel this payment {0}.",
    cancelPaymentLinkLabel: "Cancel Payment",
    cancelPaymentLinkText: "here",
    dialogTitle: "Cancel Payment!",
    dialogContent: "Are you sure you want to cancel this payment?",
    dialogCancel: "Cancel Payment",
    back: "Back",
  },

  GuestPaymentCancelled: {
    paymentCancelled: "Payment Cancelled!",
    sentViaMail:
      "We're sorry. We cannot continue processing your payment digitally. Your disbursement will be sent via check. Thank you.",
  },

  GuestPayoutRejected: {
    heading: "Reject Payout Form",
    success:
      "You have successfully rejected your payout. Click {0} to end your session. ",
    hereHyperlink: "here",
    reason: "Reason for rejecting:",
    createAccount: "Create Carat Digital Payout Account",
    confirmationTitle: "Reject Payout Confirmation",
  },

  GuestRejectPayout: {
    heading: "Reject Payout Form",
    title: "Reject Payout",
  },

  Error: {
    default: "Oops, something went wrong.",
  },

  NotFound: {
    notFoundStatusCode: "404",
    notFound: "Page not found",
    returnToDashboard: "Return to Dashboard",
  },

  Profile: {
    title: "Profile",
  },

  ECheckErrorConfirmPayout: {
    payoutErrorMessage:
      "We encountered an issue requesting your eCheck. We will continue processing and email you once this has been completed. You may receive an email from Checkbook.io with the link to your check prior to receiving an email from us. If so, follow the instructions in that email to access your check.",
    done: "Done",
    title: "Payout Message",
    payoutProgress: "Your payout is in progress.",
  },

  ECheckForm: {
    info: "Before we can request your check, please read and accept the Terms and Conditions below.",
    submit: "Submit",
    cancel: "Cancel",
    title: "Accept eCheck Terms and Conditions",
    multiRecipientInfo: "The check will be payable to only two recipients.",
    popupMsg:
      "By continuing here, you are sending your information to a third party (Checkbook.io) for processing. Click {0} to review their Privacy Policy. If you do not wish to move forward, select ‘Cancel’ and you will be directed to select a different payout method.",
    popupHere: "here",
    payoutErrorMsg:
      "We encountered an issue requesting your eCheck. We will continue processing and email you once this has been completed. You may receive an email from Checkbook.io with the link to your check prior to receiving an email from us. If so, follow the instructions in that email to access your check.",
  },

  TimeoutError: {
    done: "Done",
    title: "Payout Message",
    payoutProgress: "Your payout is in progress.",
  },

  TermsAndConditionsCheckbox: {
    acceptTerms: "I have viewed and accepted the ",
    termsConditionsLink: "Terms and Conditions.",
  },

  NicknameTextField: {
    label: "Account Nickname",
  },

  MoneyNetworkEnrollDeclined: {
    title: "Money Network Enrollment Details",
    content:
      "Unfortunately we are not able to enroll you into a Money Network card program at this time. Please select or add a new payout method.",
    continueBtn: "Continue",
  },
  UsdebitcardEnrollDeclined: {
    title: "US Debit Card Enrollment Details",
    content:
      "Unfortunately, we are not able to enroll you into the prepaid US Debit Card program at this time.  Please select a different payout method.",
    continueBtn: "Continue",
  },

  NewMoneyNetworkForm: {
    usdebitcardEnrollHeading: "Register for your prepaid US Debit Card.",
    moneyNetwork: "Money Network",
    firstName: "First Name",
    lastName: "Last Name",
    address1: "Address Line 1",
    address2: "Address Line 2",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    shipToAboveAddress: "Ship Card to the above address",
    or: "Or",
    enterShippingAddress: "Enter your shipping address",
    ssn: "Social Security Number",
    dob: "Date of Birth",
    email: "Email Address",
    primaryPhone: "Primary Phone Number",
    mobilePhone: "Mobile Phone Number",
    primaryIsMobile: "My Primary Number is a Mobile Number",
    userName: "User Name",
    pw: "Password",
    confirmPw: "Confirm Password",
    securityQuestion1: "Security Question 1",
    securityQuestion2: "Security Question 2",
    answer1: "Answer 1",
    answer2: "Answer 2",
    note: "Note",
    noteContent:
      "You must click and view the document shared below and click the first checkbox before proceeding",
    accountAndTransactionLimit:
      "By checking this box, I confirm that I have read and agree to the terms and condition of the {0} and the {1}",
    privacyNotice:
      "By checking this box, I acknowledge receipt of the issuing bank’s {0}",
    termsAndConditions:
      "By checking this box, I acknowledge receipt of the issuing bank's {2} and confirm that I have read and agree to the terms and conditions of the {0} and the {1}",
    accountHolderAgreement: "Account Holder Agreement",
    feeAndTransactionLimit: "Fee and Transaction Limit Schedule.",
    privacyPolicy: "Privacy Notice",
    noticesAndDisclosures:
      "By checking this box, I agree to receive all notices, disclosures, and communications electronically.",
    importantInfoForOpeningNewAccount:
      "IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT",
    importantInfoContent:
      "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask your name, address, date of birth and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.",
    enroll: "Enroll",
    cancel: "Cancel",
    shippingDetails: "Shipping Details",
    validDOB:
      "Please enter a valid date (" + shortDateFormat.toUpperCase() + ")",
    validSSN: "Please enter a valid Social Security Number (XXX-XX-XXXX)",
    validUserName: "Please enter a valid User Name (letters and numbers only)",
    userNameLength: "User Name must be at least 6 characters",
    pwsDoNotMatch: pwsDoNotMatch,
    termsLabel: "Terms and Conditions",
    noticesLabel: "Notices and Disclosures",
    reviewAccountAgreement:
      "You must open and review the Account Holder Agreement prior to checking this box.",
    reviewFeeAgreement:
      "You must open and review the Transaction Limit Schedule prior to checking this box.",
    privacyAgreement:
      "You must open and review the Privacy Notice prior to checking this box.",
  },

  MoneyNetworkSuccess: {
    successMsg: "Your Money Network account was successfully created.",
    lastFour: "The last four digits of your Money Network account are",
    continue: "Continue",
    transfer:
      "Click {0} if you want to transfer this payout to your new Money Network account. Otherwise, click {1} to enter a different payout method.",
    submit: "Submit",
    cancel: "Cancel",
  },
  USDebitCardSuccess: {
    successMsg:
      "Your prepaid US Debit Card was successfully created. Check your email for further instructions.",
    lastFour:
      "The last four digits of your prepaid US Debit Card number ends in",
    continue: "Continue",
    transfer:
      "Click {0} if you want to transfer this payout to your new prepaid US Debit Card. Otherwise, click {1} to enter a different payout method.",
    submit: "Submit",
    cancel: "Cancel",
  },

  StreetAddressForm: {
    address1: "Address Line 1",
    address2: "Address Line 2",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    invalidCharacter: "Invalid character entered.",
  },
};
