<template>
  <div v-if="payout" class="d-flex flex-column justify-center align-center">
    <v-spacer class="pb-10" />

    <v-icon class="pt-15" color="success" size="75" data-test="check-mark-icon">
      mdi-check-decagram-outline
    </v-icon>

    <div
      class="text-h3 pt-15"
      :class="{ 'success--text': claimed, 'text-center': claimed }"
      data-test="payout-success-message"
    >
      {{
        inReview
          ? $t("ConfirmDisbursePayout.inReviewMessage", {
              merchant: formattedGroupName,
            })
          : inPaymentReviewOrHoldStatus
          ? $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage")
          : successMsg
      }}
    </div>

    <template v-if="claimed">
      <div class="py-10" data-test="claimed-notice">
        {{ $t("ConfirmDisbursePayout.claimedNotice") }}
      </div>
      <div data-test="claimed-instruction">
        {{
          $t("ConfirmDisbursePayout.claimedInstruction", {
            txId: payout.transactionId,
          })
        }}
      </div>
    </template>

    <template v-else>
      <div v-if="isECheck" class="pt-5" data-test="eCheck-info">
        {{ $t("ConfirmDisbursePayout.eCheckMessage") }}
      </div>

      <div class="text-center">
        <div class="pt-10" data-test="processed-date">
          <span class="font-weight-bold">
            {{
              inReview
                ? $t("ConfirmDisbursePayout.paymentAccepted")
                : $t("ConfirmDisbursePayout.processed")
            }}
          </span>
          {{ currentDate() }}
        </div>

        <div v-if="inReview" data-test="payout-expected-deposit-date">
          <span class="font-weight-bold">
            {{ $t("ConfirmDisbursePayout.payoutExpectedDepositDate") }} </span
          >{{ shortDate(payoutExpectedDepositDate) }}
        </div>

        <div class="pt-2" data-test="transaction-id">
          <span class="font-weight-bold"
            >{{ $t("ConfirmDisbursePayout.transactionId") }} </span
          >{{ payout.transactionId }}
        </div>
      </div>

      <div
        v-if="checkImageUrl"
        class="d-flex align-center w-full pt-8 justify-center"
      >
        <v-img :src="checkImageUrl" data-test="check-image" />
      </div>

      <div class="pt-8">
        <v-btn
          class="mx-2"
          tile
          variant="outlined"
          elevation="0"
          color="primary"
          min-width="125"
          data-test="done-button"
          @click="done"
        >
          {{ $t("ConfirmDisbursePayout.done") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mdiCheckDecagramOutline } from "@mdi/js";
import { format } from "date-fns";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { shortDate } from "@/filters/filters";

import {
  isClaimed,
  isIR,
  isPaymentReviewOrHoldStatus,
  isMultiRecipient,
  getOtherRecipient,
  getRecipient,
} from "@/utils/payout";
import { ref } from "vue";

export default {
  name: "ConfirmDisbursePayout",

  props: {
    checkImageUrl: { type: String, default: "" },
  },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();

    const inReview = ref(false);
    const inPaymentReviewOrHoldStatus = ref(false);
    const payoutExpectedDepositDate = ref("");
    const iRObj = isIR();
    inReview.value = iRObj.isIR;
    const paymentReviewObj = isPaymentReviewOrHoldStatus("PR");
    const paymentHoldObj = isPaymentReviewOrHoldStatus("PH");
    inPaymentReviewOrHoldStatus.value =
      paymentReviewObj.PR || paymentHoldObj.PH;

    if (inReview.value) {
      payoutExpectedDepositDate.value = iRObj.payoutExpectedDepositDate;
      useTitle(i18n.global.t("ConfirmDisbursePayout.inReviewTitle"));
    } else {
      useTitle(i18n.global.t("ConfirmDisbursePayout.title"));
    }

    return {
      dialogPanelStore,
      payoutStore,
      payoutMethodStore,
      inReview,
      payoutExpectedDepositDate,
      shortDate,
      inPaymentReviewOrHoldStatus,
    };
  },

  data() {
    return {
      mdiCheckDecagramOutline,
    };
  },

  computed: {
    payout() {
      return this.payoutStore.selected;
    },

    successMsg() {
      return this.claimed
        ? i18n.global.t("ConfirmDisbursePayout.payoutClaimed")
        : i18n.global.t("ConfirmDisbursePayout.payoutSuccess");
    },

    claimed() {
      return isClaimed(this.payout);
    },

    isECheck() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "ECHECK" ||
          getOtherRecipient(this.payout).source === "ECHECK"
        );
      } else {
        return this.payoutMethodStore.selected.source === "ECHECK";
      }
    },

    formattedGroupName() {
      return this.payoutStore?.selected?.merchant?.formattedGroupName;
    },
  },

  methods: {
    done() {
      this.dialogPanelStore.hide();
      this.payoutStore.selected = null;
      this.payoutMethodStore.selected = null;
    },

    currentDate() {
      return format(new Date(), String(i18n.global.t("dateFormat.short")));
    },
  },
};
</script>
