<template>
  <div>
    <v-col cols="2">
      <USDbitLogo class="usdebit-network-logo" />
    </v-col>
    <div class="px-4 primary--text">
      {{ $t("UsDebitCardForm.optionExplaination", { merchant: merchantName }) }}
    </div>
    <v-radio-group v-model="optionSelected" class="px-4" inline row>
      <div class="margin-set primary--text">
        {{ $t("UsDebitCardForm.yesOption") }}
      </div>
      <v-radio
        :value="true"
        :aria-label="$t('UsDebitCardForm.yesOption')"
        data-test="yes-option"
      />
      <div class="margin-set primary--text addSpace">
        {{ $t("UsDebitCardForm.noOption") }}
      </div>
      <v-radio
        :value="false"
        :aria-label="$t('UsDebitCardForm.noOption')"
        data-test="no-option"
      />
    </v-radio-group>
    <v-progress-linear v-if="loadingSignup" indeterminate></v-progress-linear>
    <div class="pt-4 px-4 primary--text">
      {{ $t("UsDebitCardForm.explainationOne", { merchant: merchantName }) }}
    </div>
    <div class="pt-4 px-4 primary--text">
      {{ $t("UsDebitCardForm.explainationTwo") }}
    </div>
    <div class="pt-4 px-4 primary--text">
      {{ $t("UsDebitCardForm.explainationThree") }}
    </div>
    <v-btn
      variant="outlined"
      tile
      class="mx-auto d-flex px-4 my-4"
      data-test="cancel-transfer-btn"
      @click="showConfirmCancel = true"
    >
      {{ $t("GuestTransfer.cancel") }}
    </v-btn>
    <confirm-dialog
      v-model="showConfirmCancel"
      :title="$t('GuestTransfer.confirmCancelDialog.title')"
      :confirm-button-text="$t('GuestTransfer.confirmCancelDialog.cancel')"
      :cancel-button-text="$t('GuestTransfer.confirmCancelDialog.close')"
      @input:confirm="setContent(NewPayoutMethods)"
    >
      <i18n-t keypath="GuestTransfer.confirmCancelDialog.content" tag="span">
        <span class="font-weight-bold">{{
          $t("GuestTransfer.confirmCancelDialog.close")
        }}</span>

        <span class="font-weight-bold">{{
          $t("GuestTransfer.confirmCancelDialog.cancel")
        }}</span>
      </i18n-t>
    </confirm-dialog>
  </div>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog";
import { useDynamicContent } from "@/composables/dynamic-content";
import NewPayoutMethods from "@/components/payout-methods/NewPayoutMethods";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useAuthStore } from "@/stores/auth";
import { usePayoutStore } from "@/stores/payout";
import { useBack } from "@/composables/back";
import { ref } from "vue";
import MoneyNetworkForm from "@/components/payout-methods/money-network/MoneyNetworkForm";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { resolveErrorMsg } from "@/utils/error-handler";
import { useAppStore } from "@/stores/app";
import NewMoneyNetworkForm from "@/components/payout-methods/money-network/NewMoneyNetworkForm";
import USDbitLogo from "@/assets/img/usdebitcardlogo.svg?inline";

export default {
  name: "UsDebitCard",
  components: {
    ConfirmDialog,
    USDbitLogo,
  },
  setup() {
    const authStore = useAuthStore();
    const payoutMethodStore = usePayoutMethodStore();
    const appStore = useAppStore();
    const payoutStore = usePayoutStore();
    const optionSelected = ref(null);
    const prepaidType = ref(payoutStore.getPayoutTypeSelectedSource);
    const { setContent } = useDynamicContent();

    const title = authStore.isGuest
      ? i18n.global.t("UsDebitCardForm.addUsDebitCard")
      : i18n.global.t("UsDebitCardForm.usDebitCard");
    useTitle(title);

    payoutStore.setFormCurrentLandingState("US_DEBIT_FORM_1");
    if (authStore.isGuest) {
      useBack(() => setContent(NewPayoutMethods));
    }

    return {
      authStore,
      setContent,
      optionSelected,
      payoutMethodStore,
      appStore,
      loadingSignup: false,
      prepaidType,
      payoutStore,
    };
  },
  data() {
    return {
      showConfirmCancel: false,
      merchantName: "",
      NewPayoutMethods,
    };
  },
  watch: {
    optionSelected(newVal) {
      if (newVal === true) {
        this.setContent(MoneyNetworkForm);
      }
      if (newVal === false) {
        this.createSession();
      }
    },
  },
  created() {
    /* Part of DSDDPBS-1703 - Add Merchant name to interface. */
    this.merchantName =
      this.authStore.merchantData.extraInfo?.find(
        (item) => item.key === "formattedGroupName"
      ).value ?? "";
  },
  beforeUnmount() {
    this.payoutStore.setFormCurrentLandingState("");
  },

  methods: {
    async createSession() {
      this.loadingSignup = true;
      /* This will be replaced by the usdebit session once api is provided by the backend. Currently pointed to Money network session  */
      await this.payoutMethodStore
        .createMoneyNetworkSession({
          brand: this.prepaidType,
          transactionId: this.payoutStore?.selected?.transactionId,
        })
        .then((res) => {
          this.setContent({
            component: NewMoneyNetworkForm,
            attrs: { enrollmentData: res.data },
          });
        })
        .catch((err) => {
          const msg = resolveErrorMsg(err, "");
          this.appStore.showError(msg);
        })
        .finally(() => {
          this.loadingSignup = false;
        });
    },
  },
};
</script>

<style scoped>
.usdebit-network-logo {
  height: 6rem;
  top: 1rem;
  position: relative;
}
.margin-set {
  margin-right: 0.2rem;
  padding-top: 0.4rem;
}
.addSpace {
  margin-left: 10px;
}
</style>
