import { useAuthStore } from "@/stores/auth";
import {
  multiPaymentStatuses,
  paymentStatuses,
} from "@/utils/payment-statuses";
import { usePayoutStore } from "@/stores/payout";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import ConfirmDisbursePayout from "@/components/payouts/available/disbursements/ConfirmDisbursePayout";
import PayoutDetails from "@/components/payouts/PayoutDetails";
import { compareDesc, parse } from "date-fns";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { usePayoutSortfilterStore } from "@/stores/payout-sortfilter";
import { i18n } from "@/plugins/i18n";
import { currency } from "@/filters/filters";

export function getRecipient(payout) {
  const authStore = useAuthStore();
  return payout.recipient.find(
    (r) => r.recipientId === authStore?.user?.recipientId
  );
}

export function getPaymentStatus(payout) {
  return getRecipient(payout)?.payments.paymentStatus;
}

export function isDisbursed(payout) {
  return getPaymentStatus(payout) === "DI";
}

export function isApproved(payout) {
  return getPaymentStatus(payout) === "DA";
}

export function isClaimed(payout) {
  return getPaymentStatus(payout) === "DS";
}

export function isReview(payout) {
  return getPaymentStatus(payout) === "IR";
}

export function isPaymentReview(payout) {
  return getPaymentStatus(payout) === "PR";
}

export function isPaymentHold(payout) {
  return getPaymentStatus(payout) === "PH";
}

export function isPending(payout) {
  return getPaymentStatus(payout) === "DP";
}

export function isDeclined(payout) {
  return getPaymentStatus(payout) === "DD";
}

export function isMultiRecipient(payout) {
  return payout.recipient.length > 1;
}

export function getOtherRecipient(payout) {
  const authStore = useAuthStore();
  return payout.recipient.find(
    (r) => r.recipientId !== authStore.user.recipientId
  );
}

export function getOtherRecipientPaymentStatus(payout) {
  return isMultiRecipient(payout)
    ? getOtherRecipient(payout).payments.paymentStatus
    : undefined;
}

export function getPaymentStatusDisplayConfig(payout) {
  const status = getPaymentStatus(payout);
  return isMultiRecipient(payout)
    ? multiPaymentStatuses[status] ?? paymentStatuses[status]
    : paymentStatuses[status];
}

export function isIR() {
  const payoutStore = usePayoutStore();
  const selectedPayout = payoutStore.selected;

  const isRecipientIR = isMultiRecipient(selectedPayout)
    ? getPaymentStatus(selectedPayout) === "IR" ||
      getOtherRecipientPaymentStatus(selectedPayout) === "IR"
    : isReview(selectedPayout);

  return {
    isIR: isRecipientIR,
    payoutExpectedDepositDate: isRecipientIR
      ? getPayoutExpectedDepositDate(selectedPayout)
      : null,
  };
}

export function isPaymentReviewOrHoldStatus(status) {
  const payoutStore = usePayoutStore();
  const selectedPayout = payoutStore.selected;

  const isRecipientStatus = isMultiRecipient(selectedPayout)
    ? getPaymentStatus(selectedPayout) === status ||
      getOtherRecipientPaymentStatus(selectedPayout) === status
    : status === "PR"
    ? isPaymentReview(selectedPayout)
    : isPaymentHold(selectedPayout);

  return {
    [status]: isRecipientStatus,
  };
}

function getPayoutExpectedDepositDate(data) {
  for (const recipient of data.recipient) {
    if (recipient.payments.paymentStatus === "IR") {
      return recipient.payments.payoutExpectedDepositDate;
    }
  }
  return null;
}

export function getOtherRecipientPaymentStatusDisplayConfig(payout) {
  const status = getOtherRecipientPaymentStatus(payout);
  if (!status) return null;

  return multiPaymentStatuses[status] ?? paymentStatuses[status];
}

export function sortPayouts(payouts) {
  const sorted = [...payouts];
  sorted.sort((a, b) => {
    const dateLeft = parse(a.expiryDate, "MM/dd/yyyy", new Date());
    const dateRight = parse(b.expiryDate, "MM/dd/yyyy", new Date());
    let equal = compareDesc(dateLeft, dateRight);

    if (equal === 0) {
      equal = a.merchant.formattedGroupName.localeCompare(
        b.merchant.formattedGroupName
      );
    }

    return equal;
  });

  return sorted;
}

export function select(payout) {
  const payoutStore = usePayoutStore();
  const dialogPanelStore = useDialogPanelStore();
  const payoutMethodStore = usePayoutMethodStore();

  if (payoutMethodStore.selected) {
    payoutMethodStore.selected = null;
  }

  if (payoutStore.selected === payout) {
    payoutStore.selected = null;
    dialogPanelStore.hide();
  } else {
    payoutStore.selected = payout;

    const claimed = isClaimed(payout);

    dialogPanelStore.show(claimed ? ConfirmDisbursePayout : PayoutDetails);
  }
}

export function setColorAndSortOnMdiIcons() {
  const payoutSortfilterStore = usePayoutSortfilterStore();
  setActiveColor(
    "mdiSort",
    payoutSortfilterStore.uiSortManagement.headerFlagList.mdiSortActiveStatus
  );
  setActiveColor(
    "mdiFilterVariant",
    payoutSortfilterStore.uiSortManagement.amountFlag.mdiFilterVariantStatus
  );
  setActiveColor(
    "filter-btn-amount",
    payoutSortfilterStore.uiSortManagement.amountFlag.amountFilterActive
  );
  headerSetActive(payoutSortfilterStore);
}

function multiLevelSortingMerchantThenAmount(payoutSortfilterStore) {
  payoutSortfilterStore.uiSortManagement.filteredHistory.sort((a, b) => {
    return (
      (a.merchant.formattedGroupName === b.merchant.formattedGroupName
        ? 0
        : a.merchant.formattedGroupName < b.merchant.formattedGroupName
        ? -1
        : 1) ||
      (a.totalTransactionAmount.total === b.totalTransactionAmount.total
        ? 0
        : a.totalTransactionAmount.total < b.totalTransactionAmount.total
        ? 1
        : -1)
    );
  });
}

function headerMerchantSortUtils(payoutSortfilterStore) {
  if (
    payoutSortfilterStore.uiSortManagement.headerFlagList
      .merchantSortAssendingStatus
  ) {
    payoutSortfilterStore.uiSortManagement.filteredHistory.sort(function (
      a,
      b
    ) {
      return a.merchant.formattedGroupName === b.merchant.formattedGroupName
        ? 0
        : a.merchant.formattedGroupName < b.merchant.formattedGroupName
        ? -1
        : 1;
    });
  } else {
    payoutSortfilterStore.uiSortManagement.filteredHistory.sort(function (
      b,
      a
    ) {
      return a.merchant.formattedGroupName === b.merchant.formattedGroupName
        ? 0
        : a.merchant.formattedGroupName < b.merchant.formattedGroupName
        ? -1
        : 1;
    });
  }
  return payoutSortfilterStore.uiSortManagement.filteredHistory;
}

function headerAmountSortUtils(payoutSortfilterStore) {
  if (
    !payoutSortfilterStore.uiSortManagement.headerFlagList
      .amountSortDescendingStatus
  ) {
    payoutSortfilterStore.uiSortManagement.filteredHistory.sort(function (
      a,
      b
    ) {
      return a.totalTransactionAmount.total === b.totalTransactionAmount.total
        ? 0
        : a.totalTransactionAmount.total < b.totalTransactionAmount.total
        ? -1
        : 1;
    });
  } else {
    payoutSortfilterStore.uiSortManagement.filteredHistory.sort(function (
      b,
      a
    ) {
      return a.totalTransactionAmount.total === b.totalTransactionAmount.total
        ? 0
        : a.totalTransactionAmount.total < b.totalTransactionAmount.total
        ? -1
        : 1;
    });
  }
  return payoutSortfilterStore.uiSortManagement.filteredHistory;
}

function setActiveColor(element, flag) {
  let nodeRef = document.getElementById(element);
  nodeRef?.firstChild?.classList?.remove(headerSortCreationObject().blue_class);
  if (flag) {
    nodeRef?.firstChild?.classList?.add(headerSortCreationObject().blue_class);
  }
}

function headerSetActive(payoutSortfilterStore) {
  const setDataObj = setData(payoutSortfilterStore);
  if (setDataObj) {
    setTimeout(() => {
      const node1 = document.getElementById(setDataObj.node_1);
      const node2 = document.getElementById(setDataObj.node_2);
      node1?.firstChild?.classList?.remove(setDataObj.blue_class);
      node2?.firstChild?.classList?.remove(setDataObj.blue_class);

      let node3 = document.getElementById(setDataObj.node_3);
      let node4 = document.getElementById(setDataObj.node_4);
      node3?.firstChild?.classList?.add(setDataObj.blue_class);
      node4?.firstChild?.classList?.add(setDataObj.blue_class);
    });
  }
}

function setData(payoutSortfilterStore) {
  if (payoutSortfilterStore.uiSortManagement.amountFlag.amountFilterActive) {
    payoutSortfilterStore.uiSortManagement.filteredHistory =
      payoutSortfilterStore.uiSortManagement.filteredHistory.filter((obj) => {
        return (
          obj.totalTransactionAmount.total >=
            payoutSortfilterStore.uiSortManagement.amountFlag.minAmount &&
          obj.totalTransactionAmount.total <=
            payoutSortfilterStore.uiSortManagement.amountFlag.maxAmount
        );
      });
  }

  if (payoutSortfilterStore.uiSortManagement.headerFlagList.amountSortClicked) {
    headerAmountSortUtils(payoutSortfilterStore);
    return headerSortCreationObject().amountSort;
  }

  if (
    payoutSortfilterStore.uiSortManagement.headerFlagList.merchantSortClicked
  ) {
    headerMerchantSortUtils(payoutSortfilterStore);
    return headerSortCreationObject().merchantSort;
  }

  multiLevelSortingMerchantThenAmount(payoutSortfilterStore);
  return false;
}

function headerSortCreationObject() {
  return {
    merchantSort: {
      node_1: "amountSort_mdiArrowDown",
      node_2: "amountSort_mdiArrowUp",
      node_3: "merchantSort_mdiArrowUp",
      node_4: "merchantSort_mdiArrowDown",
      blue_class: "fiserv-blue--text",
    },
    amountSort: {
      node_1: "merchantSort_mdiArrowUp",
      node_2: "merchantSort_mdiArrowDown",
      node_3: "amountSort_mdiArrowDown",
      node_4: "amountSort_mdiArrowUp",
      blue_class: "fiserv-blue--text",
    },
    blue_class: "fiserv-blue--text",
  };
}

export function addHours(numOfHours, dateStr) {
  const date = new Date(dateStr + "z");
  const utcAddDate = new Date(date.toUTCString().slice(0, -4));
  utcAddDate.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return utcAddDate;
}

export function isBannerGetExpired(stDate, expireDt) {
  let stdt = new Date(stDate + "Z");
  let endDt = new Date(expireDt + "Z");
  let expirationFlag = false;
  if (expireDt == "") {
    let addedDt = addHours(8, stDate); // Adding 8 hrs with statDate
    if (addedDt.getTime() < Date.now()) {
      expirationFlag = true;
    }
    if (stdt.getTime() > Date.now()) {
      expirationFlag = true;
    }
  }

  if (expireDt != "") {
    if (stdt.getTime() <= Date.now()) {
      if (stdt.getTime() < endDt.getTime()) {
        if (endDt.getTime() > Date.now()) {
          expirationFlag = false;
        } else {
          expirationFlag = true;
        }
      } else {
        expirationFlag = true;
      }
    } else {
      expirationFlag = true;
    }
  }

  return expirationFlag;
}

export function setVerbiage(getPayoutTypeSelectedSource) {
  const payoutStore = usePayoutStore();
  let formVerbiage = {
    note: i18n.global.t("DebitCardForm.note"),
    tooltipContent: i18n.global.t("DebitCardForm.addPayoutVisatooltipContent"),
    guestTitle: i18n.global.t("DebitCardForm.addDebitCard"),
    registerTitle: i18n.global.t("DebitCardForm.debitCard"),
  };
  switch (getPayoutTypeSelectedSource) {
    case "VISA":
      formVerbiage = {
        tooltipContent: payoutStore.selected
          ? getTooltipTimeFrame("DEBIT", getPayoutTypeSelectedSource)
          : i18n.global.t("DebitCardForm.addPayoutVisatooltipContent", {
              timeFrame: "same day",
            }),
        guestTitle: i18n.global.t("DebitCardForm.addVisaCard"),
        registerTitle: i18n.global.t("DebitCardForm.visaCard"),
      };
      break;
    case "MASTERCARD":
      formVerbiage = {
        //note: i18n.global.t("DebitCardForm.masterNote"),
        tooltipContent: payoutStore.selected
          ? getTooltipTimeFrame("DEBIT", getPayoutTypeSelectedSource)
          : i18n.global.t("DebitCardForm.addPayoutMasertooltipContent", {
              timeFrame: "same day",
            }),
        guestTitle: i18n.global.t("DebitCardForm.addMasterCard"),
        registerTitle: i18n.global.t("DebitCardForm.masterCard"),
      };
      break;
    case "PREPAID":
      formVerbiage = {
        cardInfo: i18n.global.t("MoneyNetworkForm.cardInfo"),
        accountInfo: i18n.global.t("MoneyNetworkForm.accountInfo"),
        guestTitle: i18n.global.t("MoneyNetworkForm.addMoneyNetwork"),
        registerTitle: i18n.global.t("MoneyNetworkForm.moneyNetwork"),
        firstName: i18n.global.t("MoneyNetworkForm.firstName"),
        lastName: i18n.global.t("MoneyNetworkForm.lastName"),
        cardNumber: i18n.global.t("MoneyNetworkForm.cardNumber"),
        confirmCardNumber: i18n.global.t("MoneyNetworkForm.confirmCardNumber"),
        accountNumber: i18n.global.t("MoneyNetworkForm.accountNumber"),
        confirmAccountNumber: i18n.global.t(
          "MoneyNetworkForm.confirmAccountNumber"
        ),
        defaultPayoutMethod: i18n.global.t(
          "MoneyNetworkForm.defaultPayoutMethod"
        ),
        submit: i18n.global.t("MoneyNetworkForm.submit"),
        addAccount: i18n.global.t("MoneyNetworkForm.addAccount"),
        cancel: i18n.global.t("MoneyNetworkForm.cancel"),
        noCard: i18n.global.t("MoneyNetworkForm.noCard"),
        signup: i18n.global.t("MoneyNetworkForm.signup"),
      };
      break;
    case "MONEYNETWORK":
      formVerbiage = {
        cardInfo: i18n.global.t("MoneyNetworkForm.cardInfo"),
        accountInfo: i18n.global.t("MoneyNetworkForm.accountInfo"),
        guestTitle: i18n.global.t("MoneyNetworkForm.addMoneyNetwork"),
        registerTitle: i18n.global.t("MoneyNetworkForm.moneyNetwork"),
        firstName: i18n.global.t("MoneyNetworkForm.firstName"),
        lastName: i18n.global.t("MoneyNetworkForm.lastName"),
        cardNumber: i18n.global.t("MoneyNetworkForm.cardNumber"),
        confirmCardNumber: i18n.global.t("MoneyNetworkForm.confirmCardNumber"),
        accountNumber: i18n.global.t("MoneyNetworkForm.accountNumber"),
        confirmAccountNumber: i18n.global.t(
          "MoneyNetworkForm.confirmAccountNumber"
        ),
        defaultPayoutMethod: i18n.global.t(
          "MoneyNetworkForm.defaultPayoutMethod"
        ),
        submit: i18n.global.t("MoneyNetworkForm.submit"),
        addAccount: i18n.global.t("MoneyNetworkForm.addAccount"),
        cancel: i18n.global.t("MoneyNetworkForm.cancel"),
        noCard: i18n.global.t("MoneyNetworkForm.noCard"),
        signup: i18n.global.t("MoneyNetworkForm.signup"),
      };
      break;
    case "US_DEBIT":
      formVerbiage = {
        cardInfo: i18n.global.t("UsDebitCardForm.cardInfo"),
        guestTitle: i18n.global.t("UsDebitCardForm.addUsDebitCard"),
        registerTitle: i18n.global.t("UsDebitCardForm.usDebitCard"),
        firstName: i18n.global.t("UsDebitCardForm.firstName"),
        lastName: i18n.global.t("UsDebitCardForm.lastName"),
        cardNumber: i18n.global.t("UsDebitCardForm.cardNumber"),
        confirmCardNumber: i18n.global.t("UsDebitCardForm.confirmCardNumber"),
        defaultPayoutMethod: i18n.global.t(
          "UsDebitCardForm.defaultPayoutMethod"
        ),
        submit: i18n.global.t("UsDebitCardForm.submit"),
        addAccount: i18n.global.t("UsDebitCardForm.addAccount"),
        cancel: i18n.global.t("UsDebitCardForm.cancel"),
      };
      break;
  }
  return formVerbiage;
}

export function getTooltipTimeFrame(source, brandname) {
  const payoutMethodStore = usePayoutMethodStore();
  const payoutmethod = payoutMethodStore.allowed.filter(
    (obj) => obj.source === source
  );

  if (payoutmethod.length > 0) {
    const portalDisplayCrncy = payoutmethod[0]?.fee?.currency;
    const from = payoutmethod[0]?.transferTime?.min;
    const to = payoutmethod[0]?.transferTime?.max;
    let timebrand = "";
    if (to === 0 && from === 0) {
      timebrand = "same day";
    } else if (to > 0 && from > 0) {
      timebrand = from + "-" + to + " days";
    } else if (from === 0 && to === 1) {
      timebrand = to + " day";
    } else if (from === 0 && to > 1) {
      timebrand = to + " days";
    }
    if (payoutmethod[0].source === "DEBIT" && brandname === "VISA") {
      return i18n.global.t("DebitCardForm.visaTooltipContent", {
        timeFrame: timebrand,
        maxAmount: payoutmethod[0]?.paymentLimit?.max
          ? currency(payoutmethod[0]?.paymentLimit?.max, portalDisplayCrncy)
          : currency(50000, portalDisplayCrncy),
      });
    }
    if (payoutmethod[0].source === "DEBIT" && brandname === "MASTERCARD") {
      return i18n.global.t("DebitCardForm.masterTooltipContent", {
        timeFrame: timebrand,
        maxAmount: payoutmethod[0]?.paymentLimit?.max
          ? currency(payoutmethod[0]?.paymentLimit?.max, portalDisplayCrncy)
          : currency(50000, portalDisplayCrncy),
      });
    }
    if (payoutmethod[0].source === "VENMO") {
      return i18n.global.t("NewPayoutMethods.venmo.tooltip", {
        timeFrame: timebrand,
      });
    }
    if (payoutmethod[0].source === "PAYPAL") {
      return i18n.global.t("NewPayoutMethods.paypal.tooltip", {
        timeFrame: timebrand,
      });
    }
  }
}

export function setDDArrivalTimeFrame(source) {
  let timebrand = calculateTimeFrame(source);
  return i18n.global.t("AcceptPayout.estimatedArrival", {
    timeFrame: timebrand,
  });
}

//Function to change note for timeFrame
export function getNoteTimeFrame(source, brand) {
  let timebrand = calculateTimeFrame(source);
  const payoutStore = usePayoutStore();
  if (payoutStore.selected) {
    if (brand === "VISA") {
      return i18n.global.tc("DebitCardForm.visaNote", {
        timeFrame: timebrand === "same day" ? "Same day" : timebrand,
      });
    } else if (brand === "MASTERCARD") {
      return i18n.global.tc("DebitCardForm.masterNote", {
        timeFrame: timebrand === "same day" ? "Same day" : timebrand,
      });
    }
  } else {
    if (brand === "VISA") {
      return i18n.global.tc("DebitCardForm.visaNote", {
        timeFrame: "Same day",
      });
    } else if (brand === "MASTERCARD") {
      return i18n.global.tc("DebitCardForm.masterNote", {
        timeFrame: "Same day",
      });
    }
  }
}

function calculateTimeFrame(source) {
  const payoutMethodStore = usePayoutMethodStore();
  const payoutmethod = payoutMethodStore.allowed.filter(
    (obj) => obj.source === source
  );
  if (payoutmethod.length > 0) {
    const from = payoutmethod[0]?.transferTime?.min;
    const to = payoutmethod[0]?.transferTime?.max;
    let timebrand = "";
    if (to === 0 && from === 0) {
      timebrand = "same day";
    } else if (to > 0 && from > 0) {
      timebrand = from + "-" + to + " business days";
    } else if (from === 0 && to === 1) {
      timebrand = to + " day";
    } else if (from === 0 && to > 1) {
      timebrand = to + " days";
    }
    return timebrand;
  }
}
