<template>
  <div class="d-flex flex-column justify-center align-center">
    <v-spacer class="pb-10" />

    <v-icon class="pt-15" color="success" size="75" data-test="check-mark-icon">
      mdi-check-decagram-outline
    </v-icon>

    <v-spacer class="pb-5" />

    <div
      class="text-h3 mt-15 mb-8"
      :class="{ 'text-success ': claimed }"
      data-test="payout-success-message"
    >
      {{
        inReview
          ? $t("GuestTransferSuccess.inReviewMessage", {
              merchant: formattedGroupName,
            })
          : inPaymentReviewOrHoldStatus
          ? $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage")
          : successMsg
      }}
    </div>

    <template v-if="claimed">
      <div data-test="claimed-notice">
        {{ $t("GuestTransferSuccess.claimedNotice") }}
      </div>

      <div class="my-10" data-test="claimed-instruction">
        {{
          $t("GuestTransferSuccess.claimedInstruction", {
            txId: payout.transactionId,
          })
        }}
      </div>
    </template>

    <template v-else>
      <div v-if="isECheck" class="pt-5" data-test="eCheck-info">
        {{ $t("GuestTransferSuccess.eCheckMessage") }}
      </div>

      <div class="pt-10" data-test="processed-date">
        <span class="font-weight-bold"
          >{{
            inReview
              ? $t("GuestTransferSuccess.paymentAccepted")
              : $t("GuestTransferSuccess.processed")
          }} </span
        >{{ currentDate() }}
      </div>

      <div v-if="inReview" data-test="payout-expected-deposit-date">
        <span class="font-weight-bold">
          {{ $t("GuestTransferSuccess.payoutExpectedDepositDate") }} </span
        >{{ shortDate(payoutExpectedDepositDate) }}
      </div>

      <div class="pt-2" data-test="transaction-id">
        <span class="font-weight-bold"
          >{{ $t("GuestTransferSuccess.transactionId") }}
        </span>
        {{ payout.transactionId }}
      </div>

      <v-spacer class="pb-5" />
    </template>

    <div
      v-if="checkImageUrl"
      class="d-flex align-center w-full pt-8 justify-center"
    >
      <v-img
        :src="checkImageUrl"
        data-test="check-image"
        class="img-max-width"
      />
    </div>
    <div v-if="hideCreateAccount" class="pt-3 caratRegisterBtn">
      <v-btn
        class="mx-2"
        tile
        variant="outlined"
        elevation="0"
        color="primary"
        min-width="125"
        :to="{ name: 'registration' }"
        data-test="register-btn"
      >
        {{ $t("GuestTransferSuccess.createAccount") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiCheckDecagramOutline } from "@mdi/js";
import { mapState } from "pinia";
import { format } from "date-fns";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { shortDate } from "@/filters/filters";

import {
  isClaimed,
  isMultiRecipient,
  getOtherRecipient,
  getRecipient,
  isIR,
  isPaymentReviewOrHoldStatus,
} from "@/utils/payout";
import merchants from "@/utils/merchants";
import { useAuthStore } from "@/stores/auth";
import { ref } from "vue";

export default {
  name: "GuestTransferSuccess",

  props: {
    checkImageUrl: { type: String, default: "" },
    mfaEnabled: { type: Boolean, default: false, required: true },
  },

  setup() {
    const payoutMethodStore = usePayoutMethodStore();
    const authStore = useAuthStore();
    const payoutStore = usePayoutStore();
    const inReview = ref(false);
    const payoutExpectedDepositDate = ref("");
    const inPaymentReviewOrHoldStatus = ref(false);
    const iRObj = isIR();
    inReview.value = iRObj.isIR;
    const paymentReviewObj = isPaymentReviewOrHoldStatus("PR");
    const paymentHoldObj = isPaymentReviewOrHoldStatus("PH");
    inPaymentReviewOrHoldStatus.value =
      paymentReviewObj.PR || paymentHoldObj.PH;
    if (inReview.value) {
      payoutExpectedDepositDate.value = iRObj.payoutExpectedDepositDate;
      useTitle(i18n.global.t("GuestTransferSuccess.inReviewTitle"));
    } else {
      useTitle(i18n.global.t("GuestTransferSuccess.title"));
    }

    return {
      payoutMethodStore,
      authStore,
      payoutStore,
      inReview,
      payoutExpectedDepositDate,
      shortDate,
      inPaymentReviewOrHoldStatus,
    };
  },

  data() {
    return {
      mdiCheckDecagramOutline,
      guestUser: this.authStore.retriveRegistrationStatus,
      // existingUser: true,
    };
  },

  computed: {
    ...mapState(usePayoutStore, { payout: "selected" }),

    successMsg() {
      return this.claimed
        ? i18n.global.t("GuestTransferSuccess.payoutClaimed")
        : i18n.global.t("GuestTransferSuccess.payoutSuccess");
    },

    claimed() {
      return isClaimed(this.payout);
    },

    isECheck() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "ECHECK" ||
          getOtherRecipient(this.payout).source === "ECHECK"
        );
      } else {
        return this.payoutMethodStore.selected.source === "ECHECK";
      }
    },

    merchantHideCreateAccount() {
      return (
        merchants[this.payout.merchant.groupName.toLowerCase()]
          ?.hideCreateAccount ?? false
      );
    },

    hideCreateAccount() {
      return (
        !this.emailRegistered &&
        this.mfaEnabled &&
        !this.merchantHideCreateAccount &&
        !this.guestUser
      );
    },

    emailRegistered() {
      return this.authStore?.user?.emailRegistered;
    },

    formattedGroupName() {
      return this.authStore?.retriveFormattedGroupName;
    },
  },

  created() {},

  methods: {
    currentDate() {
      return format(new Date(), String(i18n.global.t("dateFormat.short")));
    },
  },
};
</script>

<style scoped>
.caratRegisterBtn a:hover {
  opacity: 1;
  background: #000000 !important;
  border: 2px solid #64ccff !important;
  color: rgb(var(--v-theme-on-primary)) !important;
}
@media (min-width: 961px) {
  .img-max-width {
    max-width: 37vw;
  }
}

@media (max-width: 960px) {
  .img-max-width {
    max-width: 74vw;
  }
}
</style>
